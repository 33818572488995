import { SystemReaction, hideContextFields } from "~/entities/system-reactions";
import { AppEvent, AppEvents } from "~/shared/lib/app-event-bus";

export default new SystemReaction({
  id: "hide-fields-on-create-user-form",

  scopes: [AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW],

  priority: 0,

  matcher: (event) => {
    const e = event as AppEvent<AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW>;

    if (e.payload.collectionName === "directus_users") return true;

    return false;
  },

  action: async (event, context) => {
    hideContextFields(
      [
        "id",
        "provider",
        "avatar",
        "location",
        "title",
        "description",
        "tags",
        "language",
        "theme",
        "tfa_secret",
        "email_notifications",
        "token",
        "last_page",
        "last_access",
        "external_identifier",
        "request",
        "declarant",
        "preferences_divider",
        "admin_divider",
        "status",
      ],
      context,
    );
  },
});

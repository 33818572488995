import { IField } from "~/entities/field";
import CollectionInterface from "~/api/collections/entities/CollectionInterface";
import { getDisplayExtensionItem } from "~/api/extensions";

/**
 * Most useful case is receive relational field name
 * with all nested fields, included in display template
 */
export function adjustFieldsForDisplays(
  fields: readonly string[],
  collection: CollectionInterface,
): string[] {
  const adjustedFields: string[] = fields
    .map((fieldKey) => {
      const field: IField | undefined = collection.fieldsInfo.find(
        (fieldInfo) => fieldInfo.name === fieldKey,
      );

      if (!field || field.meta?.display === null) return fieldKey;

      const display = getDisplayExtensionItem(field.meta.display);
      if (!display || !display.fields) return fieldKey;

      let fieldKeys: string[] | null = null;

      if (Array.isArray(display.fields)) {
        fieldKeys = display.fields.map(
          (relatedFieldKey: string) => `${fieldKey}.${relatedFieldKey}`,
        );
      }

      if (typeof display.fields === "function") {
        fieldKeys = display
          .fields(field.meta.displayOptions, {
            collectionName: field.collectionName,
            fieldName: field.name,
          })
          .map((relatedFieldKey) => `${fieldKey}.${relatedFieldKey}`);
      }

      if (fieldKeys) {
        return fieldKeys.map((fieldKey) => {
          const hasThumbnails =
            fieldKey.includes("$thumbnail") && field.collectionName === "directus_files";

          if (hasThumbnails) {
            return fieldKey
              .split(".")
              .filter((part) => part !== "$thumbnail")
              .join(".");
          }

          return fieldKey;
        });
      }

      return fieldKey;
    })
    .flat();

  return adjustedFields;
}

export function getFieldsFromTemplate(template: string): string[] {
  if (template === null) return [];
  const regex = /{{(.*?)}}/g;
  const fields = template.match(regex);
  if (!Array.isArray(fields)) {
    return [];
  }
  return fields.map((field) => {
    return field.replace(/{{/g, "").replace(/}}/g, "").trim();
  });
}

import { computed, ref, watch, type Ref } from "vue";
import * as yup from "yup";
import { IItem } from "~/entities/item";
import { IField } from "../lib/types";
import { transformRulesToSchema } from "../lib/parsers";
import { handleFieldValidationErrors } from "./handle-field-validation-errors";

export const useFieldValidation = (item: Ref<IItem>, field: Ref<IField>) => {
  const validationSchema = ref<yup.Schema | undefined>(undefined);

  const isValid = ref<boolean>(false);
  const errors = ref<string[]>([]);
  const isTouched = ref<boolean>(false);

  const setTouched = (): void => {
    isTouched.value = true;
  };

  watch(
    (): [IItem, IField] => [item.value, field.value],
    ([item, field]: [IItem, IField]) => {
      validationSchema.value = transformRulesToSchema(field);
      const data = item.getDataProperty(field.name);
      validate(data);
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch(
    () => isTouched.value,
    () => {
      const data = item.value.getDataProperty(field.value.name);
      validate(data);
    },
  );

  function validate(data: any): void {
    try {
      if (!validationSchema.value || !isTouched.value) return;

      validationSchema.value.validateSync(data);

      isValid.value = true;
      errors.value = [];
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        isValid.value = false;
        errors.value = handleFieldValidationErrors(field.value, error);
        return;
      }

      throw error;
    }
  }

  return {
    validationSchema,
    errors: computed(() => errors.value),
    isValid: computed(() => isValid.value),
    isTouched: computed(() => isTouched.value),
    setTouched,
  };
};

import { App } from "vue";
import { FeatureInterface } from "~/api";
import {
  ColumnInterface,
  getInternalColumnInterfaces,
  registerColumnInterfaces,
} from "~/entities/datatable-column-interfaces";

export interface DatatableFeatureInterface {
  getColumnInterface(id: string): ColumnInterface;
}

export class DatatableFeature implements FeatureInterface, DatatableFeatureInterface {
  public readonly id: string = "datatableFeature";

  private _columnInterfaces: Record<string, ColumnInterface> = {};

  register(app: App): Promise<void> | void {
    const internalColumnInterfaces = getInternalColumnInterfaces();

    registerColumnInterfaces(internalColumnInterfaces, app);
    for (const columnInterface of internalColumnInterfaces) {
      this._columnInterfaces[columnInterface.id] = columnInterface;
    }
  }

  unregister(): Promise<void> | void {}

  getColumnInterface(id: string): ColumnInterface {
    return id in this._columnInterfaces
      ? this._columnInterfaces[id]
      : this._columnInterfaces["default"];
  }
}

import { showError, useNuxtApp } from "#app";
import { defineStore } from "pinia";
import { readFields } from "@directus/sdk";
import { Field } from "@directus/types";
import { IField, castFieldInfoFromDataStudioApiToEntity } from "~/entities/field";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import { logger } from "~/service/logger/logger";
import { useRelationsStore } from "../../../stores/relations";

export const FIELDS_STORE_ID: string = "fieldsStore";

export interface FieldsState {
  fieldsInfo: IField[];
}

export const useFieldsStore = defineStore(FIELDS_STORE_ID, {
  state(): FieldsState {
    return {
      fieldsInfo: [],
    };
  },

  getters: {
    getPrimaryField:
      (state) =>
      (collectionName: string): IField | undefined =>
        state.fieldsInfo.find(
          (fieldInfo) =>
            fieldInfo.collectionName === collectionName && fieldInfo.meta.isPrimaryKey,
        ),

    getAllByCollection:
      (state) =>
      (collectionName: string): IField[] =>
        state.fieldsInfo.filter((field) => field.collectionName === collectionName),

    getManyWithCollection:
      (state) =>
      (collection: string, fieldNames: string[]): IField[] =>
        state.fieldsInfo
          .filter((field) => field.collectionName === collection)
          .filter((field) => fieldNames.includes(field.name)),
  },

  actions: {
    /**
     *
     */
    async initialize(): Promise<void> {
      const { $i18n } = useNuxtApp();
      const { client: dataStudioClient } = useDataStudioClient();

      const fieldsResponse = await dataStudioClient.request(readFields());

      const fieldsData = fieldsResponse as Field[] | null;
      if (!fieldsData) {
        logger().error(`Can't receive fields. Server returns incorrect body.`);

        showError({
          statusCode: 500,
          statusMessage: $i18n.t("fatal_error_network_resources"),
          fatal: true,
        });
        return;
      }

      const castedFields = fieldsData.map(castFieldInfoFromDataStudioApiToEntity);

      this.$patch({
        fieldsInfo: castedFields,
      });

      logger().debug(`Fields Store was initialized`);
    },

    async deinitialize(): Promise<void> {
      this.$reset();
    },

    isFieldNameRelational(fieldName: string): boolean {
      return fieldName.includes(".");
    },

    getField(collectionName: string, fieldName: string): IField | undefined {
      if (this.isFieldNameRelational(fieldName)) {
        return this.getRelationalField(collectionName, fieldName);
      }

      /**
       * @todo missed field.meta.group property
       */
      return (
        this.fieldsInfo.find(
          (fieldInfo) =>
            fieldInfo.collectionName === collectionName && fieldInfo.name === fieldName,
        ) || undefined
      );
    },

    /**
     * @todo TESTS
     * @param collectionName
     * @param fieldsExpression
     * @returns
     */
    getRelationalField(
      collectionName: string,
      fieldsExpression: string,
    ): IField | undefined {
      const relationsStore = useRelationsStore();

      const [relationFieldName, ...nestedFields] = fieldsExpression.split(".");

      if (relationFieldName.includes(":")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, collection] = relationFieldName.split(":");
        return this.getField(collection, nestedFields.join("."));
      }

      const fieldInfo =
        this.fieldsInfo.find((fieldInfo) => fieldInfo.name === relationFieldName) ||
        undefined;
      if (fieldInfo === undefined) return undefined;

      const relations = relationsStore.getRelationsByField(
        collectionName,
        fieldInfo.name,
      );

      const relation =
        relations?.find((relation) => {
          return (
            relation.fieldName === relationFieldName ||
            relation.meta?.oneField === relationFieldName
          );
        }) ?? undefined;

      if (relation === undefined) return undefined;

      const relatedCollection =
        relation.fieldName === relationFieldName
          ? relation.relatedCollection
          : relation.collectionName;

      if (relatedCollection === null) return undefined;

      const relatedField = nestedFields.join(".");
      return this.getField(relatedCollection, relatedField);
    },
  },
});

import { IField, IFieldMeta } from "../lib/types";

export class Field implements IField {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly type: string,
    readonly collectionName: string,
    readonly meta: Partial<IFieldMeta>,
  ) {}
}

import CollectionInterface from "~/api/collections/entities/CollectionInterface";
import { IField } from "~/entities/field";
import RelationInterface, {
  RelationManyInterface,
  RelationMetaInterface,
  RelationTypes,
} from "./RelationInterface";

export default class RelationMany implements RelationManyInterface {
  constructor(
    readonly type: RelationTypes,
    readonly collectionName: string,
    readonly fieldName: string,
    readonly relatedCollection: string | null = null,
    readonly relatedFieldName: string | null,
    readonly meta: RelationMetaInterface | null = null,
    readonly junction: RelationInterface | undefined,
    readonly junctionCollection: CollectionInterface | undefined,
    readonly junctionField: IField | undefined,
    readonly reverseJunctionField: IField | undefined,
  ) {}
}

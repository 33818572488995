import "reflect-metadata";
import { Logger } from "pino";
import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { IPushTokenStorageDriver } from "./types";

@injectable()
export class LocalStorageDriver implements IPushTokenStorageDriver {
  constructor(
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  DEVICE_PUSH_TOKEN_CACHE_KEY: string = "devicePushToken";

  async getToken(): Promise<string | undefined> {
    try {
      const token = window.localStorage.getItem(this.DEVICE_PUSH_TOKEN_CACHE_KEY);

      token &&
        this._logger.info(
          { token },
          `[Local-storage-driver] : Successfull recieved device token from LocalStorage.}`,
        );

      return token ?? undefined;
    } catch (err) {
      this._logger.error(
        { err },
        `[Local-storage-driver] : Cant get device token from LocalStorage.`,
      );
      throw err;
    }
  }

  async writeToken(token: string): Promise<void> {
    try {
      window.localStorage.setItem(this.DEVICE_PUSH_TOKEN_CACHE_KEY, token);

      this._logger.info(
        { token },
        `[Local-storage-driver] : Wrote device token to LocalStorage`,
      );
    } catch (err) {
      if (err instanceof DOMException && err.code === DOMException.QUOTA_EXCEEDED_ERR) {
        this._logger.error(
          { err },
          `[Local-storage-driver]: Can't write device token to local storage. Quta exceed error `,
        );

        throw err;
      }

      this._logger.error(
        { err },
        `[Local-storage-driver]: Wrote device token failure. Unhandled error.`,
      );
      throw err;
    }
  }
}

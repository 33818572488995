import { io, Socket } from "socket.io-client";
import SocketConnectionInterface from "./SocketConnectionInterface";

export default class SocketConnection implements SocketConnectionInterface {
  private _socket: Socket;

  constructor(private readonly _url: string) {
    this._socket = io(this._url, {
      autoConnect: false,
      rejectUnauthorized: false,
    });
  }

  get socket() {
    return this._socket;
  }

  connect(): void {
    if (this._socket.connected) return;
    this._socket.connect();
  }

  disconnect(): void {
    if (this._socket.disconnected) return;
    this._socket.disconnect();
  }
}

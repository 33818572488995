<template>
  <div>
    <PDropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :virtualScrollerOptions="virtualScrollerOptions"
      :editable="false"
      :emptySelectionMessage="$t('field_select_empty_selection')"
      :emptyFilterMessage="$t('field_select_filter_empty_items')"
      :emptyMessage="$t('field_select_empty_items')"
      :tabindex="props.field.meta.sortPosition"
      :aria-label="getFieldLabel(props.field)"
      :class="['w-full']"
      inputClass="form-select"
      :loading="isItemsLoading"
      :placeholder="
        isItemsLoading ? $t('items_is_loading') : $t('field_select_empty_selection')
      "
      :disabled="isItemsLoading || props.field.meta.isReadonly"
      :modelValue="props.item.getDataProperty(props.field.name)"
      @update:modelValue="(event: unknown) => emit('update:modelValue', event)"
      v-on="dropdownEventListeners"
    >
      <template #header>
        <div class="p-dropdown-header">
          <div class="p-dropdown-filter-container">
            <input
              type="text"
              autocomplete="off"
              role="search"
              :value="search"
              :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
              v-on="searchEventListeners"
            />

            <span :class="['p-dropdown-filter-icon', 'pi pi-search']" />
          </div>
        </div>
      </template>

      <template #value="slotProps">
        <div
          v-if="!!itemsHashMap[slotProps.value] && !!slotProps.value"
          class="flex items-center justify-between"
        >
          <RenderTemplate
            :collectionName="props.collection.id"
            :item="itemsHashMap[slotProps.value]"
            :fieldInfo="props.field"
            :defaultTemplate="defaultRenderTemplate"
          />

          <Button
            v-if="canNavigateToRelationalItem(collection)"
            :variant="'soft-secondary'"
            class="ml-2"
            @click="routeToItem(collection.id, itemsHashMap[slotProps.value].id, router)"
          >
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </Button>
        </div>

        <template v-else>
          <span>
            {{ slotProps.placeholder }}
          </span>
        </template>
      </template>

      <template #option="slotProps">
        <RenderTemplate
          v-if="!!itemsHashMap[slotProps.option.value]"
          :collectionName="props.collection.id"
          :item="itemsHashMap[slotProps.option.value]"
          :fieldInfo="props.field"
          :defaultTemplate="props.renderTemplate || defaultRenderTemplate"
        ></RenderTemplate>

        <template v-else>
          {{ slotProps.option.label }}
        </template>
      </template>
    </PDropdown>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useRouter } from "vue-router";
  import PDropdown from "primevue/dropdown";
  import CollectionInterface from "~/api/collections/entities/CollectionInterface";
  import { canNavigateToRelationalItem } from "~/api/collections/utils/availability";
  import { IField, getFieldLabel } from "~/entities/field";
  import { useDropdownItemsController } from "~/service/dropdown-items/composables/useDropdownItemsController";
  import { RenderTemplate } from "~/entities/render-template";
  import Button from "~/shared/ui/Button";
  import { routeToItem, type IItem, type ItemID } from "~/entities/item";

  interface Props {
    collection: CollectionInterface;
    item: IItem;
    field: IField;
    renderTemplate?: string;
  }

  const props = defineProps<Props>();

  const emit = defineEmits({
    "update:modelValue": null,
  });

  const router = useRouter();

  const {
    items,
    options,
    search,
    isLoading: isItemsLoading,
    virtualScrollerOptions,
    searchEventListeners,
    dropdownEventListeners,
  } = useDropdownItemsController(
    computed(() => props.collection),
    computed(() => props.field),
  );

  const itemsHashMap = computed<Record<ItemID, IItem>>(() =>
    items.value.reduce<Record<ItemID, IItem>>((hashMap, item) => {
      if (item.id in hashMap) return hashMap;

      hashMap[item.id] = item;
      return hashMap;
    }, {}),
  );

  const defaultRenderTemplate = computed<string>(() => {
    const fieldTemplate = props.field.meta.displayOptions?.template;

    if (!!fieldTemplate) return fieldTemplate;

    const collectionTemplate = props.collection.meta?.displayTemplate;

    const primaryField = props.collection.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    );

    return collectionTemplate || `{{ ${primaryField?.name} }}`;
  });
</script>

<style scoped></style>

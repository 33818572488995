<template>
  <div class="files-container">
    <Alert
      v-if="!relationInfo"
      :variant="'secondary'"
      :dismissible="false"
      class="flex items-center"
    >
      <Lucide icon="AlertCircle" class="w-6 h-6 mr-2" />

      {{ $t("field_relationship_not_setup") }}
    </Alert>

    <DropzoneUploader
      v-else
      ref="refDropzoneUploader"
      :urlUpload="uploadUrl"
      :existingFile="existingFiles"
      :maxFiles="maxFiles"
      :acceptedFiles="''"
      :isHideProgressBeforeUpload="true"
      :previewsContainer="refCustomPreviewsContainer"
      :previewTemplate="fileItemPreviewTemplate"
      :customOptions="dropzoneCustomOptions"
      :classDropzoneContainer="[dropzoneContainerClass]"
      :styleDropzoneContainer="dropzoneContainerStyles"
      :isDisabled="$props.field.meta.isReadonly"
      :thumbnailClickCb="onThumbnailClicked"
      v-on="dropzoneListeners"
    >
      <template #customPreviewsContainer>
        <div
          ref="refCustomPreviewsContainer"
          class="dropzone dropzone--ivo-reboot grid grid-cols-6 gap-2"
          style="border: none"
        ></div>
      </template>
    </DropzoneUploader>

    <div v-if="!$props.field.meta.isReadonly" class="grid grid-cols-6 gap-2">
      <div class="flex justify-start items-center">
        <GridItemAddFile @click.prevent="onClickAddFile">
          <template #icon>
            <i class="fa-solid fa-add text-3xl"></i>
          </template>
        </GridItemAddFile>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useRuntimeConfig, useNuxtApp } from "#app";
  import { Component, computed, ref, watch, shallowRef } from "vue";
  import { SlideData } from "photoswipe";
  import isNil from "lodash/isNil";
  import { NotyfEvent } from "notyf";
  import { useRelationM2M } from "~/api/relations/composables/useRelationM2M";
  import { useRelationMultiple } from "~/api/relations/composables/useRelationMultiple";
  import { FieldManyRelationalData, defineFieldManyData } from "~/entities/field";
  import { QueryMany } from "~/api/data-queries/types";
  import {
    castItemFileToDropzoneFile,
    castDropzoneQueuedFileToItemFile,
  } from "~/entities/file";
  import { logger } from "~/service/logger/logger";
  import { useDropdownItemsController } from "~/service/dropdown-items/composables/useDropdownItemsController";
  import {
    DropzoneUploader,
    FileGridItem,
    GridItemAddFile,
  } from "~/shared/ui/DropzoneUploader/ui";
  import type {
    DropzoneExistingMockedFile,
    EmitDropzoneSuccess,
    EmitDropzoneRemoved,
    EmitDropzoneAddedFile,
    EmitDropzoneSending,
  } from "~/shared/ui/DropzoneUploader/lib/types";
  import { DropzoneItemThumbnailClickController } from "~/shared/ui/DropzoneUploader";
  import { toaster } from "~/service/toaster";
  import { ToastSeverities } from "~/service/toaster/configs/toasts";
  import { useCollecitonsStore } from "~/stores/collections";
  import { readSlideDataFromFile } from "~/shared/ui/PhotoSwipe";
  import { useAuthStore } from "~/stores/auth";
  import { IItem, castDirectusItemToEntity } from "~/entities/item";
  import Alert from "~/shared/ui/Alert";
  import Lucide from "~/shared/ui/Lucide";
  import { FieldFormInterfaceProps } from "../types";
  import { FieldInterfaceEmitId, FieldInterfaceEmits } from "../emits";
  import type { DropzoneFile } from "dropzone";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const {
    $i18n: { t },
  } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();

  const authStore = useAuthStore();
  const collectionStore = useCollecitonsStore();

  const authToken = computed(() => authStore.accessToken);

  const { relationInfo, relatedCollection } = useRelationM2M(
    computed(() => props.collection.id),
    computed(() => props.field),
  );

  /**
   * @note Start Shared functional from ListM2M
   */
  const itemsRequestQuery = computed<QueryMany<unknown>>(() => {
    const query = {
      limit: -1,
      fields: ["id"], // todo: fields.value
    };

    if (!relationInfo.value) return query;

    return query;
  });

  const { fetchedItems: initialJunctionItems } = useRelationMultiple(
    computed(() => props.item.id),
    relationInfo,
    itemsRequestQuery,
  );

  const relatedPrimaryFieldExpression = computed<string>(() => {
    const junctionFieldName = relationInfo.value?.junctionField?.name;
    const relatedCollectionPrimaryFieldKey = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;

    if (!junctionFieldName || !relatedCollectionPrimaryFieldKey) return "";

    return `${junctionFieldName}.${relatedCollectionPrimaryFieldKey}`;
  });

  const junctionPKExpression = computed<string>(() => {
    return relationInfo.value?.junctionCollection?.getPrimaryFieldInfo()?.name ?? "";
  });

  /**
   * @note End Shared functional from ListM2M
   */

  const { items: filesAvailableForChoose } = useDropdownItemsController(
    computed(() => relatedCollection.value),
    computed(() => props.field),
  );

  // dropzone logic
  const uploadUrl = `${runtimeConfig.public.dataStudioApiUrl}/files`;
  const maxFiles = 10;

  const finalizePromiseController = ref<any>(null);

  const existingFiles = shallowRef<DropzoneFile[]>([]);

  const thumbnailOnClickController = new DropzoneItemThumbnailClickController();

  watch(
    () => [props.item, initialJunctionItems.value, filesAvailableForChoose.value],
    async ([newItem, newJunctionItems, newAvailableFiles]) => {
      if (!newItem) return;
      const result: DropzoneFile[] = [];
      const fieldData = (newItem as IItem).getDataProperty(
        props.field.name,
      ) as FieldManyRelationalData;

      if (isNil(fieldData)) {
        logger().warn(
          `FieldData ${props.field.name} is null. Check permissions for current role`,
        );
        return;
      }

      const selectedJunctionItemIds = fieldData.currentJunctionItemIds
        .map((itemId) =>
          (newJunctionItems as IItem[]).find(
            (item) => item.getDataProperty(junctionPKExpression.value) === itemId,
          ),
        )
        .filter((junctionItem): junctionItem is IItem => junctionItem !== undefined)
        .map((junctionItem) =>
          junctionItem.getDataProperty(relatedPrimaryFieldExpression.value),
        )
        .filter((relatedItemId) => relatedItemId !== undefined);

      const uploadedFiles = (newAvailableFiles as IItem[]).filter((item) =>
        selectedJunctionItemIds.includes(item.id),
      );

      for (const file of uploadedFiles) {
        const transformedFile = await castItemFileToDropzoneFile(file, authToken.value);

        if (transformedFile === undefined) {
          logger().warn(`unable to transform file to DropzoneFile. Result is undefined.`);
          continue;
        }

        result.push(transformedFile);
      }

      for (const file of fieldData.create) {
        const transformedFile = await castItemFileToDropzoneFile(file, authToken.value);

        if (transformedFile === undefined) {
          logger().warn(`unable to transform file to DropzoneFile. Result is undefined.`);
          continue;
        }
        result.push(transformedFile);
      }

      const uniqueFiles = result.reduce<DropzoneFile[]>((accumulator, dropzoneFile) => {
        const fileExists =
          accumulator.find((file) => file.upload?.uuid === dropzoneFile.upload?.uuid) !==
          undefined;

        if (fileExists) {
          return accumulator;
        }

        accumulator.push(dropzoneFile);
        return accumulator;
      }, []);

      existingFiles.value = uniqueFiles;
    },
    {
      immediate: true,
    },
  );

  const refCustomPreviewsContainer = ref<HTMLElement | undefined>(undefined);

  const fileItemPreviewTemplate: string = FileGridItem.template as string;

  const lightboxDatasources = shallowRef<SlideData[]>([]);

  watch(
    () => existingFiles.value,
    async (newFiles) => {
      try {
        const slideDatasources = await Promise.all(
          newFiles.map((file) => readSlideDataFromFile(file)),
        );

        lightboxDatasources.value = slideDatasources;
      } catch (err) {
        logger().error({ err }, `unable to processing some files to thumbnails`);
      }
    },
    {
      immediate: true,
    },
  );

  const onThumbnailClicked = (
    event: unknown,
    dropzoneFile: DropzoneExistingMockedFile,
  ) => {
    logger().debug({ event, dropzoneFile }, `Thumbnail clicked`);
    thumbnailOnClickController.handle(dropzoneFile);
  };

  const dropzoneCustomOptions = {
    thumbnailMethod: "contain",
    dictRemoveFile: t("file_remove"),
    parallelUploads: 3,
  };

  const dropzoneContainerClass =
    "btn btn-m btn-full rounded-xl text-uppercase font-900 shadow-s bg-blue-dark";

  const dropzoneContainerStyles = {
    minHeight: "auto",
    height: `0`,
    padding: `0!important`,
    opacity: `0`,
  };

  const refDropzoneUploader = ref<Component | null>(null);

  const openFileExplorer = ref<Function | null>(null);

  watch(
    () => refDropzoneUploader.value?.fileController?.open,
    (newOpenFE) => {
      openFileExplorer.value = newOpenFE;
    },
    {
      deep: true,
    },
  );

  const processQueue = () => {
    const filesForUpload = refDropzoneUploader.value?.queueController.queuedFiles;

    if (!filesForUpload?.length) {
      logger().debug(
        {
          fieldName: props.field.name,
        },
        "no files to upload",
      );

      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.resolve();
      }
    }

    /**
     * @todo промис не разрешится
     */
    refDropzoneUploader.value?.queueController.start();
  };

  const onClickAddFile = () => {
    if (isNil(openFileExplorer.value)) {
      return;
    }

    openFileExplorer.value();
  };

  const isFileUploaded = (fileUUID: string): boolean => {
    const fieldData = props.item.getDataProperty(
      props.field.name,
    ) as FieldManyRelationalData;

    const expression = relatedPrimaryFieldExpression.value;

    const fileItemJunctionID = initialJunctionItems.value.find(
      (junctionItem) => junctionItem.getDataProperty(expression) === fileUUID,
    )?.id;

    return fileItemJunctionID === undefined
      ? false
      : fieldData.currentJunctionItemIds.includes(fileItemJunctionID);
  };

  const dropzoneListeners = {
    "dropzone:success": (event: EmitDropzoneSuccess) => {
      logger().debug({ event }, "File uploaded successfully");

      if (!event.serverResponse?.data) {
        logger().warn(
          {
            response: event.serverResponse,
          },
          `file upload response returned incorrect data. Unable to parse item`,
        );
        return;
      }

      const fileCollection = collectionStore.getCollection("directus_files");
      if (fileCollection === undefined) {
        logger().error(
          `not found files collection for parse fields info for create file item`,
        );
        return;
      }

      const parsedItem = castDirectusItemToEntity(
        "directus_files",
        event.serverResponse.data,
      );

      logger().debug({ parsedItem }, `parsed file item from server response`);

      const fieldData: FieldManyRelationalData = props.item.getDataProperty(
        props.field.name,
      );

      const uploadedFileIndex = fieldData.create.findIndex(
        (item) => item.id === event.file.upload?.uuid,
      );
      if (uploadedFileIndex === -1) {
        logger().error(
          { fileUUID: event.file.upload?.uuid },
          `not found uploaded file index for remove from create field data property`,
        );
        return;
      }

      const fieldDataCreateProperty = [...fieldData.create];
      fieldDataCreateProperty.splice(uploadedFileIndex, 1, parsedItem);

      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: defineFieldManyData({
          ...fieldData,
          create: fieldDataCreateProperty,
        }),
      });
    },

    "dropzone:removed": (event: EmitDropzoneRemoved) => {
      logger().debug({ event }, "file removed");

      const fieldData: FieldManyRelationalData = props.item.getDataProperty(
        props.field.name,
      );

      const fileUUID = event.file.upload?.uuid || "";
      if (isFileUploaded(fileUUID)) {
        // mark file item for remove from storage
        const expression = relatedPrimaryFieldExpression.value;

        const fileItemJunctionID = initialJunctionItems.value.find(
          (junctionItem) => junctionItem.getDataProperty(expression) === fileUUID,
        )?.id;

        if (fileItemJunctionID === undefined) {
          logger().error(
            {
              fileUUID,
              junctionRelatedPKExpression: expression,
            },
            `unable to mark item file for remove. Not found Junction Item. File will not be remove from storage.`,
          );
          return;
        }

        const fileForRemoveCurrentIndex = fieldData.currentJunctionItemIds.findIndex(
          (id) => id === fileItemJunctionID,
        );

        if (fileForRemoveCurrentIndex === -1) {
          logger().error(
            {
              fileForRemoveCurrentIndex,
            },
            `unable to mark item file for remove. Not found junction item id index in current data.`,
          );
          return;
        }

        const updatedDataCurrent = [...fieldData.currentJunctionItemIds];
        updatedDataCurrent.splice(fileForRemoveCurrentIndex, 1);

        const updatedFieldData = defineFieldManyData({
          ...fieldData,
          currentJunctionItemIds: updatedDataCurrent,
          removeJunctionItemIds: fieldData.removeJunctionItemIds.includes(
            fileItemJunctionID,
          )
            ? fieldData.removeJunctionItemIds
            : fieldData.removeJunctionItemIds.concat([fileItemJunctionID]),
        });

        emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
          collectionName: props.collection.id,
          fieldName: props.field.name,
          updatedData: updatedFieldData,
        });

        return;
      }

      const itemIndexForUpload = fieldData.create.findIndex(
        (item) => item.getDataProperty("id") === fileUUID,
      );

      if (itemIndexForUpload === -1) {
        logger().error(
          {
            fileUUID,
            itemIndexForUpload,
          },
          `unable to remove not uploaded file from data. Item not found in data`,
        );

        return;
      }

      const updatedDataCreate = [...fieldData.create];
      updatedDataCreate.splice(itemIndexForUpload, 1);

      const updatedData = defineFieldManyData({
        ...fieldData,
        create: updatedDataCreate,
      });

      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData,
      });
    },

    "dropzone:sending": (event: EmitDropzoneSending) => {
      logger().debug({ event }, "prepare file to upload");

      event.xhr.setRequestHeader("Authorization", `Bearer ${authToken.value}`);
    },

    "dropzone:addedFile": (event: EmitDropzoneAddedFile) => {
      logger().debug({ event }, "file added");

      if (event.addedFile.isMocked) {
        logger().debug(
          { file: event.addedFile },
          `Skip write file to field data. Is Mocked`,
        );
        return;
      }

      const fieldData: FieldManyRelationalData = props.item.getDataProperty(
        props.field.name,
      );

      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: defineFieldManyData({
          ...fieldData,
          create: fieldData.create?.concat(
            castDropzoneQueuedFileToItemFile(event.addedFile),
          ),
        }),
      });
    },

    "dropzone:complete": (event: { file: DropzoneFile }) => {
      logger().debug({ event }, "file upload completed");
    },

    "dropzone:queueComplete": (event: unknown) => {
      logger().debug({ event }, "files queue completed");

      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.resolve();
      }
    },

    "dropzone:error": (event: unknown) => {
      logger().error({ event }, "received dropzone error");

      toaster()
        .error("Не удалось обновить запись. Нажмите чтобы узнать подробнее")
        .on(NotyfEvent.Click, () => {
          toaster().open({
            type: ToastSeverities.WARNING,
            message: JSON.parse(event?.message?.errors ?? []),
          });
        });
      /**
       * @todo проверить event.message.code
       */
      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.reject();
      }
    },
  };

  const finalize = async () => {
    logger().debug({ fieldName: props.field.name }, "start component finalization");

    const promiseResult = new Promise((resolve, reject) => {
      finalizePromiseController.value = { resolve, reject };

      processQueue();
    }).finally(() => {
      logger().debug({ fieldName: props.field.name }, "finished component finalization");
    });

    return promiseResult;
  };

  defineExpose({ finalize });
</script>

<style scoped></style>
shared/lib/file/readers shared/lib/file/read-slide-data-from-file

import { IItem } from "~/entities/item";
import { getFieldExpressionFromDisplayTemplate } from "./get-field-expression-from-display-template";

export const createTextFromDisplayTemplate = (
  item: IItem,
  displayTemplate: string,
): string | null => {
  const expression = getFieldExpressionFromDisplayTemplate(displayTemplate);

  const result = item.getDataProperty(expression);
  return result ?? null;
};

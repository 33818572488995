import { inject, injectable } from "inversify";
import { Logger } from "pino";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { AuthCredentials } from "~/service/auth/types/AuthCredentials";
import { useAuthStore } from "~/stores/auth";
import { AuthGatewayInterface } from "./types";

@injectable()
export class AuthGateway implements AuthGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  async authByEmail(AuthCredentials: AuthCredentials): Promise<boolean> {
    try {
      const authStore = useAuthStore();
      return await authStore.authenticate(AuthCredentials);
    } catch (err) {
      this._logger.error({ err }, `unable to auth by  phone`);
      throw err;
    }
  }
}

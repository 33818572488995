import { Item as DirectusItem } from "@directus/types";
import { useFieldsStore } from "~/entities/field";
import { Item } from "../../model/entity/item";
import { castDirectusItemFieldDataToLocal } from "./cast-directus-item-field-data-to-local";
import type { IItem } from "../../model/interfaces";

/**
 *
 * @param {string} collection
 * @param {DirectusItem} data
 * @returns {IItem}
 */
export function castDirectusItemToEntity(collection: string, data: DirectusItem): IItem {
  const fieldsStore = useFieldsStore();

  const castedData: Record<string, any> = {};

  const fieldNamesForCast = Object.keys(data);
  const fieldsForCast = fieldsStore.getManyWithCollection(collection, fieldNamesForCast);

  for (const field of fieldsForCast) {
    const castedFieldData = castDirectusItemFieldDataToLocal(data, field);
    castedData[field.name] = castedFieldData;
  }

  return new Item(collection, castedData);
}

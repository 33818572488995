import { IItem } from "~/entities/item";
import { App, AppExternalData, AppInternalData } from "../app";

export function castItemToApp(item: IItem): App {
  return new App(
    item.collection,
    item.data as AppExternalData | AppInternalData,
    item.meta,
    item.isNew,
  );
}

import { shallowRef, ref, ComputedRef, watch, computed } from "vue";
import debounce from "lodash/debounce";
import { VirtualScrollerProps } from "primevue/virtualscroller";
import { OptionItem } from "~/components/DropdownSingleRelationRenderer/types";
import CollectionInterface from "~/api/collections/entities/CollectionInterface";
import { defineQuery } from "~/api/data-queries/defines/query";
import { QueryFilter, QueryMany } from "~/api/data-queries/types";
import { IField, useFieldsStore } from "~/entities/field";
import { IItem, useLazyCollectionItems } from "~/entities/item";

export const useDropdownItemsController = (
  collection: ComputedRef<CollectionInterface | null>,
  fieldInfo: ComputedRef<IField | undefined>,
) => {
  const fieldsStore = useFieldsStore();

  const collectionFieldNames = computed<string[]>(() =>
    !!collection.value?.id
      ? fieldsStore.getAllByCollection(collection.value!.id).map((field) => field.name)
      : ["*"],
  );

  const castCollectionItemToOption = (item: IItem): OptionItem => {
    return {
      label: String(item.id) || "",
      value: item.id,
    };
  };

  const virtualScrollerOptions = shallowRef<VirtualScrollerProps>({
    lazy: true,
    showLoader: false,
    appendOnly: true,
    numToleratedItems: 0,
    loaderDisabled: true,
    orientation: "vertical",
    itemSize: 42,
  });

  const filters = ref<QueryFilter<unknown> | undefined>(undefined);

  watch(
    () => fieldInfo.value?.meta.options.filter,
    (newFilters) => {
      filters.value = newFilters;
    },
    {
      immediate: true,
    },
  );

  const search = ref<string>("");

  const searchEventListeners = {
    input: (event: InputEvent) => {
      const target = event.target as HTMLInputElement;

      search.value = target.value;
    },
  };

  const createItemsRequestQuery = (
    filters: QueryMany<unknown>["filter"],
    search: QueryMany<unknown>["search"] | undefined,
  ): QueryMany<unknown> => {
    return defineQuery({
      search: search,
      filter: {
        ...filters,
      },
      fields: collectionFieldNames.value,
    });
  };

  const itemsRequestQuery = shallowRef<QueryMany<unknown>>(
    createItemsRequestQuery(filters.value, search.value),
  );

  watch(
    () => [search.value, filters.value],
    (newData) => {
      const [newSearch, newFilters] = newData;

      itemsRequestQuery.value = createItemsRequestQuery(
        newFilters as QueryFilter<unknown>,
        newSearch as string,
      );
    },
  );

  const {
    collectionItems,
    load: loadItems,
    isLoading: isItemsLoading,
  } = useLazyCollectionItems();

  const debouncedUpdateItems = debounce(async () => {
    await loadItems(collection.value?.id ?? "", itemsRequestQuery.value);
  }, 500);

  watch(
    () => collection.value,
    async (newCollection) => {
      if (!newCollection) return;

      await debouncedUpdateItems();
    },
    {
      immediate: true,
    },
  );

  watch(
    () => itemsRequestQuery.value,
    async () => {
      await debouncedUpdateItems();
    },
  );

  watch(
    () => isItemsLoading.value,
    (isLoading) => {
      virtualScrollerOptions.value = {
        ...virtualScrollerOptions.value,
        loading: isLoading,
      };
    },
    {
      immediate: true,
    },
  );

  const options = computed<OptionItem[]>(
    () => collectionItems.value?.map(castCollectionItemToOption),
  );

  const dropdownEventListeners = {
    "before-show": async () => {
      await debouncedUpdateItems();
    },
  };

  return {
    items: computed(() => collectionItems.value),
    options,
    search: computed(() => search.value),
    filters: computed(() => filters.value),
    virtualScrollerOptions,
    isLoading: isItemsLoading,
    updateItems: debouncedUpdateItems,
    searchEventListeners,
    dropdownEventListeners,
  };
};

import { useNuxtApp } from "nuxt/app";
import { getFileFromUrl } from "~/shared/lib/file";
import { PhotoSwipeBuilder } from "~/shared/ui/PhotoSwipe";
import { toaster } from "~/service/toaster";
import { DropzoneExistingMockedFile } from "../types";
import { IDropzoneController } from "./types";

export class DropzoneItemThumbnailClickController implements IDropzoneController {
  constructor() {}

  async handle(file: DropzoneExistingMockedFile) {
    if (file.type.includes("image")) {
      return this._handleImageThumbnailCLick(file);
    }
    this._handleFileThumbnailClick(file);
  }

  private async _handleImageThumbnailCLick(file: DropzoneExistingMockedFile) {
    const previewFile = await getFileFromUrl(file.dataURL as string, file.name);

    const builder = new PhotoSwipeBuilder();

    if (previewFile.type === "image/heif" || previewFile.type === "image/heic") {
      await builder.addHeicImage(previewFile);
    } else {
      builder.addImage(file);
    }

    const photoSwipe = builder.getPhotoSwipe();

    photoSwipe.init();

    return;
  }

  private async _handleFileThumbnailClick(file: DropzoneExistingMockedFile) {
    const { $i18n } = useNuxtApp();

    const previewFile = await getFileFromUrl(file.dataURL as string, file.name);

    const downloadUrl = window.URL.createObjectURL(previewFile);
    const linkEl = document.createElement("a");
    linkEl.href = downloadUrl;
    linkEl.download = file.name;
    linkEl.click();

    window.URL.revokeObjectURL(downloadUrl);
    toaster().success($i18n.t("downloading_file"));
  }
}

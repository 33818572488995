import { App } from "vue";
import { injectable } from "inversify";

export interface FeatureInterface {
  readonly id: string;

  register(app: App): Promise<void> | void;

  unregister(app: App): Promise<void> | void;
}

export interface FeaturesRepositoryInterface {
  addFeature(feature: FeatureInterface): this;

  registerFeature(id: string): this | Promise<this>;

  getRegisteredFeature(id: string): FeatureInterface | undefined;
}

@injectable()
export class FeaturesRepository implements FeaturesRepositoryInterface {
  constructor(private _app: App) {}

  private _rawFeatures: Record<string, FeatureInterface> = {};

  private _registeredFeatures: Record<string, FeatureInterface> = {};

  addFeature(feature: FeatureInterface): this {
    this._rawFeatures[feature.id] = feature;
    return this;
  }

  async registerFeature(id: string): Promise<this> {
    if (id in this._rawFeatures) {
      const feature = this._rawFeatures[id];
      await feature.register(this._app);

      this._registeredFeatures[id] = feature;
      delete this._rawFeatures[id];

      return this;
    }

    return this;
  }

  getRegisteredFeature(id: string): FeatureInterface | undefined {
    return id in this._registeredFeatures ? this._registeredFeatures[id] : undefined;
  }
}

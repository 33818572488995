import { defineNuxtPlugin } from "#app";
import { Field as DirectusField, Item as DirectusItem } from "@directus/types";
import { IItem, ItemAnyData } from "~/entities/item";
import { FieldManyRelationalData, IField } from "~/entities/field";
import { useRelationsStore } from "~/stores/relations";
import {
  defineItemFieldTransformer,
  useTransformersDatastudioStore,
} from "~/stores/transformersDatastudio";

export default defineNuxtPlugin(() => {
  const transformersStore = useTransformersDatastudioStore();

  transformersStore.registerForFieldInterface(
    "files",
    defineItemFieldTransformer({
      to: (item: IItem, field: IField): DirectusItem => {
        const fieldData: FieldManyRelationalData = item.getDataProperty(field.name);

        const transformedFieldData: {
          create: DirectusItem[];
          update: (number | string)[];
          delete: (string | number)[];
        } = {
          create: [],
          update: [],
          delete: [],
        };

        for (const newItem of fieldData.create) {
          const itemForCreate: DirectusItem = {
            directus_files_id: newItem.getDataProperty("id"),
          };

          transformedFieldData.create.push(itemForCreate);
        }

        for (const removeJunctionId of fieldData.removeJunctionItemIds) {
          transformedFieldData.delete.push(removeJunctionId);
        }

        return {
          [field.name]: transformedFieldData,
        };
      },

      from: (data: any, field: DirectusField): ItemAnyData => {
        return transformersStore.defaultItemFieldTransformer.from(data, field);
      },
    }),
  );

  transformersStore.registerForFieldInterface(
    "list-m2m",
    defineItemFieldTransformer({
      to: (item: IItem, field: IField): DirectusItem => {
        const fieldData: FieldManyRelationalData = item.getDataProperty(field.name);
        if (!fieldData)
          return {
            [field.name]: null,
          };

        const transformedFieldData: {
          create: DirectusItem[];
          update: (number | string)[];
          delete: (string | number)[];
        } = {
          create: [],
          update: [],
          delete: [],
        };

        const relationsStore = useRelationsStore();

        const relations = relationsStore.getRelationsByField(
          field.collectionName,
          field.name,
        );

        const junctionRelation = relations.find(
          (relation) =>
            relation.relatedCollection === field.collectionName &&
            relation.meta?.oneField === field.name &&
            relation.meta.junctionField,
        );

        const fieldKey = junctionRelation?.meta?.junctionField;
        if (!!fieldKey) {
          for (const relatedItemId of fieldData.newRelatedItemIds) {
            const itemForCreate: DirectusItem = {
              [fieldKey]: relatedItemId,
            };

            transformedFieldData.create.push(itemForCreate);
          }
        }

        for (const removeJunctionId of fieldData.removeJunctionItemIds) {
          transformedFieldData.delete.push(removeJunctionId);
        }

        return {
          [field.name]: transformedFieldData,
        };
      },

      from: (data: any, field: DirectusField): ItemAnyData => {
        return transformersStore.defaultItemFieldTransformer.from(data, field);
      },
    }),
  );

  transformersStore.registerForFieldInterface(
    "list-o2m",
    defineItemFieldTransformer({
      to(item: IItem, field: IField): DirectusItem {
        const fieldData: FieldManyRelationalData = item.getDataProperty(field.name);
        if (!fieldData)
          return {
            [field.name]: null,
          };

        const transformedFieldData: (string | number)[] = [
          ...fieldData.currentJunctionItemIds,
        ];

        return {
          [field.name]: transformedFieldData,
        };
      },

      from(data: any, field: DirectusField): ItemAnyData {
        return transformersStore.defaultItemFieldTransformer.from(data, field);
      },
    }),
  );

  transformersStore.registerForFieldInterface(
    "list-o2m-tree-view",
    defineItemFieldTransformer({
      to(item: IItem, field: IField): DirectusItem {
        const fieldData: FieldManyRelationalData = item.getDataProperty(field.name);
        if (!fieldData)
          return {
            [field.name]: null,
          };

        const transformedFieldData: (string | number)[] = [
          ...fieldData.currentJunctionItemIds,
        ];

        return {
          [field.name]: transformedFieldData,
        };
      },

      from(data: any, field: DirectusField): ItemAnyData {
        return transformersStore.defaultItemFieldTransformer.from(data, field);
      },
    }),
  );
});

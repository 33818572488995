import { useTransformersDatastudioStore } from "~/stores/transformersDatastudio";
import { IUser } from "~/entities/user";
import { IField, useFieldsStore } from "~/entities/field";
import { usePermissionsStore } from "~/stores/permissions";
import { PermissionAction } from "~/api/permissions/entity/PermissionInterface";
import type { User as DirectusUser } from "@directus/types";

const fieldNamesForDefaultExclude = [
  "provider",
  "last_access",
  "email_notifications",
  "external_identifier",
  "theme_dark",
  "theme_light",
  "theme_light_overrides",
  "theme_dark_overrides",
  "appearance",
];

export function castUserToDirectusUser(user: IUser): Partial<DirectusUser> {
  const fieldsStore = useFieldsStore();
  const transformersStore = useTransformersDatastudioStore();
  const permissionsStore = usePermissionsStore();

  const fieldNames: string[] = Object.keys(user.data);

  const fieldNamesPermittedForUpdate = permissionsStore.getPermittedFields(
    "directus_users",
    user.isNew ? PermissionAction.CREATE : PermissionAction.UPDATE,
  );

  const filteredFields = fieldNames
    .filter((field) => {
      return !fieldNamesForDefaultExclude.includes(field);
    })
    .filter((field) => {
      if (fieldNamesPermittedForUpdate[0] === "*") {
        return true;
      }
      return fieldNamesPermittedForUpdate.includes(field);
    });

  const fields: IField[] = filteredFields
    .map((fieldName) => fieldsStore.getField("directus_users", fieldName))
    .filter((field): field is IField => field !== undefined);
  const fieldsForTransform = fields.filter((field) => !field.meta.isHidden);

  let transformedUser: Partial<DirectusUser> = {};

  for (const field of fieldsForTransform) {
    const fieldTransformer = transformersStore.getItemFieldTransformer(
      field.meta.interface as string,
    );

    const transformedField = fieldTransformer?.to(user, field);

    transformedUser = {
      ...transformedUser,
      ...transformedField,
    };
  }

  return transformedUser;
}

import { INotyfOptions, INotyfNotificationOptions } from "notyf";

export const defineToastTypeConfig = (
  config: INotyfNotificationOptions,
): INotyfNotificationOptions => {
  return config;
};

export const createToastConfig = (): INotyfOptions => ({
  duration: 5000,
  ripple: true,
  position: {
    x: "right",
    y: "top",
  },
  dismissible: true,
  types: [
    {
      type: ToastSeverities.WARNING,
      background: "orange",
      icon: {
        tagName: "i",
        className: "fa-solid fa-exclamation",
        color: "white",
      },
      ripple: false,
    },
  ],
});

export enum ToastSeverities {
  WARNING = "warning",
}

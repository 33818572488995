import { ref, watch, type Ref } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { useFieldsStore, IField } from "~/entities/field";

export const useCollectionFields = (collectionName: Ref<string>) => {
  const fieldsStore = useFieldsStore();

  const fields = ref<Record<string, IField>>({});

  watch(
    () => collectionName.value,
    (newCollectionName) => {
      const sourceFields = fieldsStore.getAllByCollection(newCollectionName);
      const clonedFields = cloneDeep(sourceFields);

      const map: Record<string, IField> = {};

      for (const field of clonedFields) {
        map[field.name] = field;
      }

      fields.value = map;
    },
    {
      immediate: true,
    },
  );

  return { fields };
};

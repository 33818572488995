import { useRuntimeConfig } from "#app";
import { convertRemoteFileToBlob } from "~/shared/lib/file";
import { IItem } from "~/entities/item";
import { ItemFileMetadataKeys } from "../lib/constants";

export const castItemFileToBlob = async (
  item: IItem,
  token: string,
): Promise<Blob | undefined> => {
  const runtimeConfig = useRuntimeConfig();

  if (
    item.getMetaProperty(ItemFileMetadataKeys.IS_UPLOADED) === false &&
    item.getMetaProperty(ItemFileMetadataKeys.FILE_DATA) instanceof Blob
  ) {
    return item.getMetaProperty(ItemFileMetadataKeys.FILE_DATA) as Blob;
  }

  const uuid = item.getDataProperty("id") as string;

  const remoteUrl: string = `${runtimeConfig.public.dataStudioApiUrl}/assets/${uuid}?access_token=${token}`;

  const fileBlob = await convertRemoteFileToBlob(remoteUrl);
  return fileBlob;
};

<template>
  <div>
    <component :is="concreteDisplay.component" v-if="!!concreteDisplay" v-bind="props" />

    <PMesssage v-else :severity="'warn'" :closable="false" :class="'text-sm'">
      {{ $t("error_missed_relation_display_interface") }}
    </PMesssage>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import PMesssage from "primevue/message";
  import { getDisplayExtensionItem } from "~/api/extensions";
  import { IField, useFieldsStore } from "~/entities/field";
  import { FieldDisplayConfig, FieldDisplayProp } from "../types";
  import { getFieldsFromTemplate } from "../utils";

  const props = defineProps<FieldDisplayProp>();

  const fieldsStore = useFieldsStore();

  const fieldWithRelation = computed<IField | undefined>(() => {
    const templateFieldNames = getFieldsFromTemplate(`{{${props.fieldKey}}}`);
    if (!templateFieldNames.length) return undefined;

    return fieldsStore.getField(props.collectionName, templateFieldNames[0]);
  });

  const fieldInterface = computed<string | undefined>(() =>
    !!props.fieldInfo
      ? props.fieldInfo.meta.interface
      : fieldWithRelation.value?.meta.interface ?? undefined,
  );

  const concreteDisplay = computed<FieldDisplayConfig | undefined>(() => {
    if (!fieldInterface.value) return undefined;

    return getDisplayExtensionItem(`related-values-${fieldInterface.value}`) || undefined;
  });
</script>

<style scoped></style>

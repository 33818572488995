<template>
  <div>
    <Alert
      v-if="!relationInfo"
      :variant="'secondary'"
      :dismissible="false"
      class="flex items-center"
    >
      <Lucide icon="AlertCircle" class="w-6 h-6 mr-2" />

      {{ $t("field_relationship_not_setup") }}
    </Alert>

    <DropdownSingleRelationRenderer
      v-else-if="relatedCollection !== undefined && relatedCollection !== null"
      :collection="relatedCollection"
      :item="props.item"
      :field="props.field"
      @update:modelValue="onUpdateModelValue"
    />
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import DropdownSingleRelationRenderer from "~/components/DropdownSingleRelationRenderer/DropdownSingleRelationRenderer.vue";
  import { useRelationM2O } from "~/api/relations/composables/useRelationsM2O";
  import Alert from "~/shared/ui/Alert";
  import Lucide from "~/shared/ui/Lucide";
  import {
    FieldInterfaceEmitId,
    FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "../../emits";
  import { FieldFormInterfaceProps } from "../../types";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const { relationInfo, relatedCollection } = useRelationM2O(
    computed(() => props.collection.id),
    computed(() => props.field),
  );

  const onUpdateModelValue = (event: string | number | undefined | null) => {
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: event,
      }),
    );
  };
</script>

<style scoped></style>

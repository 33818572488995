import { App } from "vue";
import sortBy from "lodash/sortBy";
import { logger } from "~/service/logger/logger";
import { FieldDisplayConfig } from "./types";

export const getInternalFieldDisplays = (): FieldDisplayConfig[] => {
  const displays = import.meta.glob<FieldDisplayConfig>("./*/index.ts", {
    import: "default",
    eager: true,
  });

  logger().debug(
    {
      displays,
    },
    `Loaded internal Field Display Configs`,
  );

  return sortBy(Object.values(displays), "id");
};

export const registerFieldDisplays = (displays: FieldDisplayConfig[], app: App): void => {
  for (const display of displays) {
    app.component(`display-${display.id}`, display.component);
  }
};

import { DirectusUser } from "@directus/sdk";
import { useFieldsStore } from "~/entities/field";
import { castDirectusItemFieldDataToLocal } from "~/entities/item";
import { User, UserData, IUser } from "~/entities/user";

export function castDirectusRestUserToUser(user: DirectusUser<any>): IUser {
  const fieldsStore = useFieldsStore();

  const propertyNamesForExclude = ["last_page", "password", "tfa_secret", "token"];

  const userData: UserData = {} as UserData;
  for (const propertyName in user) {
    if (propertyNamesForExclude.includes(propertyName)) continue;

    const propertyField = fieldsStore.getField("directus_users", propertyName);

    userData[propertyName] =
      propertyField !== undefined
        ? castDirectusItemFieldDataToLocal(user, propertyField)
        : user[propertyName];
  }

  return new User(userData);
}

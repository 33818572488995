import { useRuntimeConfig } from "#app";
import axios from "axios";
import { Logger } from "pino";
import { inject, injectable } from "inversify";
import { AuthenticationData } from "@directus/sdk";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { StorageAuthDriver } from "~/shared/api/directus/auth";
import { useAuthStore } from "~/stores/auth";
import {
  AuthByPhoneServiceInterface,
  AuthCodeRequestResponse,
  AuthCodeLoginResponse,
  AuthCodeRequestDto,
  AuthCodeLoginDto,
} from "../lib";
import { castCodeAuthResultToAuthData } from "../api/mapper/cast-code-auth-result-to-auth-data";

@injectable()
export default class AuthByPhoneService implements AuthByPhoneServiceInterface {
  constructor(
    @inject(INJECT_SYMBOLS.StorageAuthDriver)
    private readonly _storageAuthDriver: StorageAuthDriver,
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  /**
   *
   * @throws {Error}
   */
  async authByPhone(data: AuthCodeRequestDto): Promise<AuthCodeRequestResponse> {
    try {
      const runtimeConfig = useRuntimeConfig();

      const response = await axios.post<{ data: AuthCodeRequestResponse }>(
        `${runtimeConfig.public.dataStudioApiUrl}/auth-phone/code`,
        data,
      );
      if (!response.data) {
        throw new Error("invalid response");
      }

      return response.data.data;
    } catch (err) {
      throw err;
    }
  }

  async authBySms(data: AuthCodeLoginDto): Promise<AuthCodeLoginResponse> {
    try {
      const runtimeConfig = useRuntimeConfig();

      const response = await axios.post<{ data: AuthCodeLoginResponse }>(
        `${runtimeConfig.public.dataStudioApiUrl}/auth-phone/auth`,
        data,
      );
      if (!response.data) {
        throw new Error("invalid response");
      }

      if ("error_message" in response.data.data) {
        return response.data.data;
      }

      const storageData: AuthenticationData = castCodeAuthResultToAuthData(
        response.data.data,
      );

      this._storageAuthDriver.storage.set(storageData);

      useAuthStore().$patch({
        accessToken: storageData.access_token!,
      });
      this._logger.info(`[${this.constructor.name}.authBySms]: set authorization`);

      return response.data.data;
    } catch (err) {
      this._logger.error({ err }, `[Auth-by-phone-service]: unable to auth by sms_code.`);
      throw err;
    }
  }
}

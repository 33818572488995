import { v4 as uuidv4 } from "uuid";
import { IField } from "~/entities/field";

/**
 * @param {IField} fieldInfo
 * @returns {string|number}
 * @throws {Error}
 */
export function generatePrimaryKey(fieldInfo: IField): string | number {
  if (fieldInfo.meta.interface !== "input") {
    throw new Error("can't generate primary key for non 'input' interface");
  }

  switch (fieldInfo.type) {
    case "uuid":
      return uuidv4();

    case "integer":
      const min = 100000;
      const max = 999999;
      return Math.floor(Math.random() * (max - min + 1) + min);

    default:
      throw new Error(
        `can't generate primary key for type '${fieldInfo.type}'. No processor for that type.`,
      );
  }
}

<template>
  <div class="files-container">
    <Alert
      v-if="!relationInfo"
      :variant="'secondary'"
      :dismissible="false"
      class="flex items-center"
    >
      <Lucide icon="AlertCircle" class="w-6 h-6 mr-2" />

      {{ $t("field_relationship_not_setup") }}
    </Alert>

    <TransitionGroup v-else name="slide-fade">
      <DropzoneUploader
        ref="refDropzoneUploader"
        :urlUpload="uploadUrl"
        :existingFile="existingFile"
        :maxFiles="1"
        :acceptedFiles="acceptedImageTypes"
        :isHideProgressBeforeUpload="true"
        :previewsContainer="refCustomPreviewsContainer"
        :previewTemplate="fileItemPreviewTemplate"
        :customOptions="dropzoneCustomOptions"
        :classDropzoneContainer="[dropzoneContainerClass]"
        :styleDropzoneContainer="dropzoneContainerStyles"
        :isDisabled="$props.field.meta.isReadonly"
        :isDownloadAllowed="false"
        :thumbnailClickCb="onThumbnailClicked"
        v-on="dropzoneListeners"
      >
        <template #customPreviewsContainer>
          <div
            ref="refCustomPreviewsContainer"
            class="dropzone dropzone--ivo-reboot flex"
            style="border: none"
          ></div>
        </template>
      </DropzoneUploader>

      <div v-if="!$props.field.meta.isReadonly && !fieldData" class="flex">
        <div class="flex justify-center items-center">
          <GridItemAddFile @click.prevent="onClickAddFile">
            <template #icon>
              <i class="fa-solid fa-add text-3xl"></i>
            </template>
          </GridItemAddFile>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
  import { useRuntimeConfig, useNuxtApp } from "#app";
  import { Component, computed, ref, watch } from "vue";
  import isNil from "lodash/isNil";
  import { NotyfEvent } from "notyf";
  import { watchDebounced } from "@vueuse/shared";
  import { useRelationM2O } from "~/api/relations/composables/useRelationsM2O";
  import CollectionInterface from "~/api/collections/entities/CollectionInterface";
  import { castItemFileToDropzoneFile } from "~/entities/file";
  import { logger } from "~/service/logger/logger";
  import {
    DropzoneUploader,
    FileGridItem,
    GridItemAddFile,
    DropzoneExistingMockedFile,
    EmitDropzoneSuccess,
    EmitDropzoneRemoved,
    EmitDropzoneAddedFile,
    EmitDropzoneSending,
  } from "~/shared/ui/DropzoneUploader";
  import { toaster } from "~/service/toaster";
  import { ToastSeverities } from "~/service/toaster/configs/toasts";
  import { DropzoneItemThumbnailClickController } from "~/shared/ui/DropzoneUploader";
  import { useAuthStore } from "~/stores/auth";
  import { useLazyCollectionItem } from "~/entities/item";
  import Alert from "~/shared/ui/Alert";
  import Lucide from "~/shared/ui/Lucide";
  import { FieldFormInterfaceProps } from "../types";
  import { FieldInterfaceEmitId, FieldInterfaceEmits } from "../emits";
  import type { DropzoneFile } from "dropzone";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const {
    $i18n: { t },
  } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();

  const authStore = useAuthStore();

  const authToken = computed(() => authStore.accessToken);

  const fieldData = computed<string | null>(() =>
    props.item.getDataProperty(props.field.name),
  );

  const { relationInfo, relatedCollection } = useRelationM2O(
    computed(() => props.collection.id),
    computed(() => props.field),
  );

  const acceptedImageTypes = ["image/*", ".psd", ".heif", ".heic"];

  const existingFile = ref<DropzoneFile>();

  const { load: loadItem, collectionItem: relatedItem } = useLazyCollectionItem();

  watchDebounced(
    (): [CollectionInterface | null, string | null] => [
      relatedCollection.value,
      fieldData.value,
    ],
    async ([collection, itemId]: [CollectionInterface | null, string | null]) => {
      if (!collection || !itemId || existingFile.value || !authToken.value) return;

      await loadItem(collection.id, String(itemId));

      const transformedFile = await castItemFileToDropzoneFile(
        relatedItem.value!,
        authToken.value,
      );

      if (!transformedFile) {
        return;
      }

      existingFile.value = transformedFile;
    },
    {
      immediate: true,
      debounce: 100,
    },
  );

  const uploadUrl = `${runtimeConfig.public.dataStudioApiUrl}/files`;

  const finalizePromiseController = ref<any>(null);

  const refCustomPreviewsContainer = ref<HTMLElement | undefined>(undefined);

  const fileItemPreviewTemplate: string = FileGridItem.template as string;

  const thumbnailOnClickController = new DropzoneItemThumbnailClickController();

  const onThumbnailClicked = (
    event: unknown,
    dropzoneFile: DropzoneExistingMockedFile,
  ) => {
    logger().debug({ event, dropzoneFile }, `Thumbnail clicked`);
    thumbnailOnClickController.handle(dropzoneFile);
  };

  const dropzoneCustomOptions = {
    thumbnailMethod: "contain",
    dictRemoveFile: t("file_remove"),
    parallelUploads: 1,
  };

  const dropzoneContainerClass =
    "btn btn-m btn-full rounded-xl text-uppercase font-900 shadow-s bg-blue-dark";

  const dropzoneContainerStyles = {
    minHeight: "auto",
    height: `0`,
    padding: `0!important`,
    opacity: `0`,
  };

  const refDropzoneUploader = ref<Component | null>(null);

  const openFileExplorer = ref<Function | null>(null);

  watch(
    () => refDropzoneUploader.value?.fileController?.open,
    (newOpenFE) => {
      openFileExplorer.value = newOpenFE;
    },
    {
      deep: true,
    },
  );

  const processQueue = () => {
    const filesForUpload = refDropzoneUploader.value?.queueController.queuedFiles;

    if (!filesForUpload?.length) {
      logger().debug(
        {
          fieldName: props.field.name,
        },
        "no files to upload",
      );

      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.resolve();
      }
    }

    refDropzoneUploader.value?.queueController.start();
  };

  const onClickAddFile = () => {
    if (isNil(openFileExplorer.value)) {
      return;
    }

    openFileExplorer.value();
  };

  const dropzoneListeners = {
    "dropzone:success": (event: EmitDropzoneSuccess) => {
      logger().debug({ event }, "File uploaded successfully");

      if (!event.serverResponse?.data) {
        logger().warn(
          {
            response: event.serverResponse,
          },
          `file upload response returned incorrect data. Unable to parse item`,
        );
        return;
      }

      const synchronizedId = event.serverResponse.data.id as string;

      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: synchronizedId,
      });
    },

    "dropzone:removed": (event: EmitDropzoneRemoved) => {
      logger().debug({ event }, "file removed");

      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: null,
      });
    },

    "dropzone:sending": (event: EmitDropzoneSending) => {
      logger().debug({ event }, "prepare file to upload");

      event.xhr.setRequestHeader("Authorization", `Bearer ${authToken.value}`);
    },

    "dropzone:addedFile": (event: EmitDropzoneAddedFile) => {
      logger().debug({ event }, "file added");
      const addedFile = event.addedFile;

      if (addedFile.isMocked === "true") {
        logger().debug(
          { file: event.addedFile },
          `Skip write file to field data. Is Mocked`,
        );
        return;
      }

      if (addedFile.upload!.uuid === fieldData.value) {
        logger().debug(
          { file: event.addedFile },
          `Skip write file to field data. Its same file`,
        );
        return;
      }

      if (!existingFile.value) {
        existingFile.value = event.addedFile;
      }

      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: event.addedFile.upload!.uuid,
      });
    },

    "dropzone:complete": (event: { file: DropzoneFile }) => {
      logger().debug({ event }, "file upload completed");
    },

    "dropzone:queueComplete": (event: unknown) => {
      logger().debug({ event }, "files queue completed");

      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.resolve();
      }
    },

    "dropzone:error": (event: unknown) => {
      logger().error({ event }, "received dropzone error");

      toaster()
        .error("Не удалось обновить запись. Нажмите чтобы узнать подробнее")
        .on(NotyfEvent.Click, () => {
          toaster().open({
            type: ToastSeverities.WARNING,
            message: JSON.parse(event?.message?.errors ?? []),
          });
        });
      /**
       * @todo проверить event.message.code
       */
      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.reject();
      }
    },
  };
  const finalize = async () => {
    logger().debug({ fieldName: props.field.name }, "start component finalization");

    const promiseResult = new Promise((resolve, reject) => {
      finalizePromiseController.value = { resolve, reject };

      processQueue();
    }).finally(() => {
      logger().debug({ fieldName: props.field.name }, "finished component finalization");
    });

    return promiseResult;
  };

  defineExpose({ finalize });
</script>

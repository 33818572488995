import "reflect-metadata";
import { Logger } from "pino";
import { inject, injectable } from "inversify";
import {
  DirectusClient,
  RestClient,
  updateUser,
  DirectusUser,
  readUsers,
  readUser,
  readMe,
} from "@directus/sdk";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { IUser, UsersGatewayInterface } from "../lib/types";
import { castUserToDirectusUser } from "./mappers/castUserToDirectusUser";
import { castDirectusRestUserToUser } from "./mappers/castDirectusRestUserToUser";

@injectable()
export default class UserGateway implements UsersGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _datastudioApi: DirectusClient<any> & RestClient<any>,
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  async getByIds(ids: string[]): Promise<IUser[]> {
    try {
      const response = await this._datastudioApi.request(
        readUsers({
          filter: {
            id: {
              _in: ids,
            },
          },
        }),
      );

      const userResponse = response as DirectusUser<any>[];

      return !!userResponse?.length ? userResponse.map(castDirectusRestUserToUser) : [];
    } catch (err) {
      this._logger.error({ err }, `unable to get users`);

      return [];
    }
  }

  async getOneUserById(id: string): Promise<IUser> {
    try {
      const response = await this._datastudioApi.request(readUser(id));

      return castDirectusRestUserToUser(response as DirectusUser<any>);
    } catch (err) {
      this._logger.error({ err }, `unable to get that user`);
      throw err;
    }
  }

  async archivateUserById(id: string): Promise<void> {
    try {
      await this._datastudioApi.request(updateUser(id, { status: "archived" }));
    } catch (err) {
      this._logger.error({ err }, `unable to archive that user`);
      throw err;
    }
  }

  async restoreUserById(id: string): Promise<void> {
    try {
      await this._datastudioApi.request(updateUser(id, { status: "active" }));
    } catch (err) {
      this._logger.error({ err }, `unable to restore that user`);
      throw err;
    }
  }

  /**
   *
   * @throws {Error}
   */
  async updateUser(id: string, data: IUser): Promise<IUser> {
    try {
      const payload = castUserToDirectusUser(data);

      // @ts-expect-error
      const response = await this._datastudioApi.request(updateUser(id, payload));

      return castDirectusRestUserToUser(response as DirectusUser<any>);
    } catch (err) {
      this._logger.error({ err }, `unable to update that user`);
      throw err;
    }
  }

  /**
   * @throws {Error}
   */
  async getMe(): Promise<IUser> {
    try {
      const response = await this._datastudioApi.request(readMe());

      return castDirectusRestUserToUser(response as DirectusUser<any>);
    } catch (err) {
      this._logger.error({ err }, `unable to receive current user`);
      throw err;
    }
  }
}

import "reflect-metadata";
import { useNuxtApp } from "#app";
import { injectable } from "inversify";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { RouteMetaFields } from "~/app/router.options";
import { getTranslateString } from "~/api/intl/utils/parsers";
import { useCollecitonsStore } from "~/stores/collections";
import { BreadcrumbsLink } from "../../lib/types";
import { parseRoutePathToParts } from "../../lib";
import { IBreadcrumbsHandler } from "./types";

@injectable()
export class BreadcrumbsCollectionHandler implements IBreadcrumbsHandler {
  constructor() {}

  handleBreadcrumbs(route: RouteLocationNormalizedLoaded): BreadcrumbsLink[] {
    try {
      const {
        $i18n: { t },
      } = useNuxtApp();

      const routePathParts = parseRoutePathToParts(route).filter(
        (part: string) => part !== "c",
      );

      if (!routePathParts.length) {
        throw new Error(
          "[Breadcrumbs-collection-handler]: routePathParts is undefined. No valid routes after parsing",
        );
      }

      const [collectionPart, itemPart] = routePathParts;

      const collectionName = collectionPart.replace("/c/", "");

      const collectionStore = useCollecitonsStore();

      const collection = collectionStore.getCollection(collectionName);

      const collectionBreadcrumb = {
        to: collectionPart,
        active: false,
        index: 0,
        label: collection?.label ?? collectionPart,
      };
      const breadcrumbs: BreadcrumbsLink[] = [collectionBreadcrumb];

      if (itemPart) {
        const routeBreadcrumbsLabel = route.meta[RouteMetaFields.BREADCRUMBS_LABEL];

        const traslatedRouteBreadcrumbsLabel = t(
          getTranslateString(routeBreadcrumbsLabel as string) as string,
        );

        const itemBreadcrumb = {
          to: itemPart,
          active: false,
          index: 1,
          label: traslatedRouteBreadcrumbsLabel ?? itemPart,
        };

        breadcrumbs.push(itemBreadcrumb);
      }

      breadcrumbs.map((breadcrumb: BreadcrumbsLink) => {
        if (breadcrumb.to === route.fullPath) {
          breadcrumb.active = true;
        }
      });

      return breadcrumbs;
    } catch (error) {
      throw error;
    }
  }
}

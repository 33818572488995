import { defineStore } from "pinia";
import { logger } from "~/service/logger/logger";
import { AppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import { ISystemReaction } from "./interfaces";

const STORE_ID = "systemReactions";

interface State {
  reactions: ISystemReaction[];
}

export const useSystemReactionsStore = defineStore(STORE_ID, {
  state(): State {
    return {
      reactions: [],
    };
  },

  getters: {
    getAllByAppEvent:
      (state) =>
      (event: AppEvent): ISystemReaction[] => {
        return state.reactions
          .filter((reaction) => reaction.scopes.includes(event.event))
          .filter((reaction) =>
            reaction.matcher(event, {
              utils: {
                AppEvents: AppEvents,
                Logger: logger(),
              },
            }),
          );
      },
  },

  actions: {
    registerReaction(reaction: ISystemReaction): void {
      if (this.reactions.findIndex((item) => item.id === reaction.id) !== -1) return;
      this.reactions.push(reaction);
    },
  },
});

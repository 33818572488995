import { useRuntimeConfig } from "#app";
import {
  createDirectus,
  rest,
  graphql,
  authentication,
  DirectusClient,
  AuthenticationClient,
  RestClient,
  GraphqlClient,
} from "@directus/sdk";
import { LocalStorageAuthDriver } from "~/shared/api/directus";

let client:
  | (DirectusClient<any> &
      AuthenticationClient<any> &
      RestClient<any> &
      GraphqlClient<any>)
  | null = null;

export function useDataStudioClient() {
  if (!!client)
    return {
      client,
    };

  const runtimeConfig = useRuntimeConfig();

  client = createDirectus(runtimeConfig.public.dataStudioApiUrl)
    .with(
      authentication("json", {
        autoRefresh: true,
        msRefreshBeforeExpires: 30000,
        // todo: composable должен принимать driver как аргумент
        storage: new LocalStorageAuthDriver().storage,
      }),
    )
    .with(rest())
    .with(graphql());

  return {
    client,
  };
}

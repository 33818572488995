<template>
  <InputSwitch
    :disabled="props.field.meta.isReadonly"
    :modelValue="$props.item.getDataProperty($props.field.name)"
    :class="{
      'p-invalid': !!errors.length && isTouched,
    }"
    @blur="setTouched"
    @update:modelValue="onModelValueUpdate"
  />

  <ValidationMessage v-if="!!errors.length" :errors="errors" />
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import InputSwitch from "primevue/inputswitch";
  import { useFieldValidation } from "~/entities/field";
  import ValidationMessage from "~/shared/ui/ValidationMessage";
  import { FieldFormInterfaceProps } from "../types";
  import {
    FieldInterfaceEmitId,
    FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "../emits";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const onModelValueUpdate = (value: boolean) => {
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: value,
      }),
    );
  };

  const { errors, isTouched, setTouched } = useFieldValidation(
    computed(() => props.item),
    computed(() => props.field),
  );
</script>

<style scoped></style>

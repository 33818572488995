import {
  RestCommand,
  Query,
  readSettings,
  readActivities,
  readOperations,
  readSingleton,
} from "@directus/sdk";

/**
 * Fixing refusing fetch system collection items.
 *
 * @note perhaps not all of system collections handled.
 *
 */
export function readSingletoneItemWithCoreCollection(
  collection: string,
  query?: Query<any, any>,
): RestCommand<any, any> {
  switch (collection) {
    case "directus_settings":
      return readSettings(query ?? {});

    case "directus_activity":
      return readActivities(query ?? {});

    case "directus_operations":
      return readOperations(query ?? {});

    default:
      if (collection.startsWith("directus_")) {
        throw new Error(
          `Missed handler for fetch singletoneItemWithCoreCollection of  '${collection}'. Please, define handler for this collection.`,
        );
      }

      return readSingleton(collection, query);
  }
}

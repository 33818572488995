import { inject, injectable } from "inversify";
import cloneDeep from "lodash/cloneDeep";
import { aggregate, type Query, type RestClient } from "@directus/sdk";
import { IItem, ItemID } from "~/entities/item";
import { useFieldsStore } from "~/entities/field";
import { useCollecitonsStore } from "~/stores/collections";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { readSingleItemWithCoreCollectionsById } from "~/service/data-studio/utils/readSingleItemWithCoreCollecionsById";
import { readItemsWithCoreCollections } from "~/service/data-studio/utils/readItemsWithCoreCollections";
import { readSingletoneItemWithCoreCollection } from "~/service/data-studio/utils/readSingletoneItemWithCoreCollection";
import type { QueryMany } from "~/api/data-queries/types";
import { adjustFieldsForDisplays } from "~/api/field-displays/utils";
import { IItemsGateway } from "../interfaces";
import { castDirectusItemToEntity } from "../mapper/cast-directus-item-to-entity";
import type { Logger } from "pino";
import type { Item as DirectusItem } from "@directus/types";

@injectable()
export class ItemsGateway implements IItemsGateway {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private _datastudioRestClient: RestClient<any>,
    @inject(INJECT_SYMBOLS.Logger)
    private _logger: Logger,
  ) {}

  /**
   *
   * @throws {Error}
   */
  async getSingletoneByQuery(
    collectionName: string,
    query?: Query<any, any>,
  ): Promise<IItem> {
    try {
      const item = await this._datastudioRestClient.request(
        readSingletoneItemWithCoreCollection(collectionName, query ?? {}),
      );

      if (!item) throw new Error("invalid response");

      return castDirectusItemToEntity(collectionName, item);
    } catch (err) {
      this._logger.error({ err }, "unable to fetch singletone item");
      throw err;
    }
  }

  /**
   *
   * @throws {Error}
   */
  async getOneById(
    collectionName: string,
    itemId: ItemID,
    query: Query<any, any>,
  ): Promise<IItem> {
    try {
      const item = await this._datastudioRestClient.request(
        readSingleItemWithCoreCollectionsById(collectionName, itemId, query),
      );

      if (!item) throw new Error("invalid response");

      return castDirectusItemToEntity(collectionName, item);
    } catch (err) {
      this._logger.error({ err }, "unable to fetch collection item");
      throw err;
    }
  }

  /**
   *
   * @throws {Error}
   */
  async getManyByQuery(
    collectionName: string,
    query: QueryMany<unknown>,
  ): Promise<{
    data: IItem[];
    meta: { filter_count: number };
  }> {
    try {
      const collectionsStore = useCollecitonsStore();

      const collection = collectionsStore.getCollection(collectionName);
      if (!collection) {
        return {
          data: [],
          meta: {
            filter_count: 0,
          },
        };
      }

      const fieldsWithRelational = !!query.fields
        ? this._getFieldsWithRelational(collectionName, query.fields as string[])
        : query.fields;

      const { archiveField } = collection!.meta;

      const fieldsWithArchive = !!archiveField
        ? fieldsWithRelational?.concat([archiveField])
        : fieldsWithRelational;

      const adjustedQuery: QueryMany<unknown> = this._mergeQueryWithPrimaryField(
        collectionName,
        {
          ...query,
          fields: fieldsWithArchive,
        },
      );

      const items: DirectusItem[] = await this._datastudioRestClient.request(
        readItemsWithCoreCollections(collectionName, adjustedQuery),
      );
      if (!items) throw new Error("incorrect response body");

      const itemsCount = await this._datastudioRestClient.request(
        aggregate(collectionName, {
          aggregate: {
            count: "*",
          },
          query: {
            ...adjustedQuery,
            page: undefined,
            limit: undefined,
          },
        }),
      );

      const meta = {
        filter_count: !!itemsCount?.[0]?.count ? Number(itemsCount[0].count) : 0,
      };

      const data = items.map((itemData) =>
        castDirectusItemToEntity(collectionName, itemData),
      );

      return {
        data,
        meta,
      };
    } catch (err) {
      this._logger.error(
        {
          err,
        },
        `unable to retrieve items`,
      );

      throw err;
    }
  }

  private _getFieldsWithRelational(
    collectionName: string,
    fieldNames: string[],
  ): string[] {
    const collectionsStore = useCollecitonsStore();
    const collection = collectionsStore.getCollection(collectionName);
    return !!collection ? adjustFieldsForDisplays(fieldNames, collection) : [];
  }

  private _mergeQueryWithPrimaryField(
    collectionName: string,
    sourceQuery: QueryMany<unknown>,
  ): QueryMany<unknown> {
    const fieldsStore = useFieldsStore();
    const primaryField = fieldsStore.getPrimaryField(collectionName);

    if (!primaryField) return sourceQuery;
    if (sourceQuery.fields?.includes(primaryField.name)) return sourceQuery;

    const query: QueryMany<unknown> = cloneDeep(sourceQuery);

    if (!query.fields) {
      query.fields = [];
    }

    if (Array.isArray(query.fields)) {
      query.fields.push(primaryField.name);
    }

    return query;
  }
}

import { RestClient, readPermissions } from "@directus/sdk";
import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { PermissionInterface } from "../entity/PermissionInterface";
import { PermissionsMapperInterface } from "../mapper/PermissionsMapperInterface";
import { PermissionsGatewayInterface } from "./PermissionsGatewayInterface";
import type { Logger } from "pino";
import type { Permission as DirectusPermission } from "@directus/types";

@injectable()
export class PermissionsDirectusGateway implements PermissionsGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _datastudioRestClient: RestClient<any>,
    @inject(INJECT_SYMBOLS.PermissionsMapperInterface)
    private readonly _permissionsMapper: PermissionsMapperInterface,
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  /**
   * @throws {Error}
   */
  async getAllByRoleId(roleId: string): Promise<PermissionInterface[]> {
    try {
      const response = await this._datastudioRestClient.request<DirectusPermission[]>(
        readPermissions({
          filter: {
            role: {
              _eq: roleId,
            },
          },
        }),
      );
      return response.map(this._permissionsMapper.transformToPermission);
    } catch (err) {
      this._logger.error({ err }, `unable to receive permissions by role`);
      throw err;
    }
  }

  async getPublicPermissions() {
    try {
      const response = await this._datastudioRestClient.request<DirectusPermission[]>(
        readPermissions({
          filter: {
            role: {
              _null: true,
            },
          },
        }),
      );
      return response.map(this._permissionsMapper.transformToPermission);
    } catch (err) {
      this._logger.error({ err }, `unable to receive permissions by role`);
      throw err;
    }
  }
}

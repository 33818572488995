import { useNuxtApp, createError } from "#app";
import { defineStore } from "pinia";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { iocContainer } from "~/inversify.config";
import { IItemsGateway } from "~/entities/item";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { castItemToSettings } from "./cast-item-to-settings";
import type { SettingsInterface } from "../lib/types";

export const SETTINGS_STORE_ID: string = "settings";

interface State {
  projectSettings: SettingsInterface | undefined;
}

export const useSettingsStore = defineStore(SETTINGS_STORE_ID, {
  state: (): State => ({
    projectSettings: undefined,
  }),

  actions: {
    async initialize(): Promise<void> {
      const { $i18n } = useNuxtApp();

      try {
        const itemsGateway = iocContainer.get<IItemsGateway>(
          INJECT_SYMBOLS.IItemsGateway,
        );

        const settingsApiResponseItem = await itemsGateway.getSingletoneByQuery(
          "directus_settings",
        );

        const appSettings = castItemToSettings(settingsApiResponseItem);

        this.$patch({
          projectSettings: appSettings,
        });
      } catch (err) {
        if (err instanceof Error) {
          logger().fatal(
            {
              err,
            },
            `Can't recieve Project Settings`,
          );
        }

        const errorMessage: string = $i18n.t("server_error_fetch_project_settings");

        toaster().error(errorMessage);

        throw createError({
          statusCode: 500,
          message: errorMessage,
          fatal: true,
        });
      }
    },

    async deinitilize(): Promise<void> {
      this.$reset();
    },
  },
});

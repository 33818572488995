import {
  RestCommand,
  readFile,
  readItem,
  readRole,
  readUser,
  Query,
  readActivity,
} from "@directus/sdk";
import toNumber from "lodash/toNumber";
import type { ItemID } from "~/entities/item";

/**
 * Fixing refusing fetch system collection items.
 *
 * @note perhaps not all of system collections handled.
 *
 */
export function readSingleItemWithCoreCollectionsById(
  collection: string,
  id: ItemID,
  query?: Query<any, any>,
): RestCommand<any, any> {
  switch (collection) {
    case "directus_users":
      return readUser(id as string, query);

    case "directus_roles":
      return readRole(id as string, query);

    case "directus_files":
      return readFile(id as string, query);

    case "directus_activity":
      return readActivity(toNumber(id), query);

    default:
      if (collection.startsWith("directus_"))
        throw new Error(
          `missed handler for fetch system collection items '${collection}'. Please, define handler for this collection.`,
        );

      return readItem(collection, id, query);
  }
}

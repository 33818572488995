import {
  Query,
  RestCommand,
  readFiles,
  readItems,
  readRoles,
  readUsers,
} from "@directus/sdk";

/**
 * Fixing refusing fetch system collection items.
 *
 * @note perhaps not all of system collections handled.
 *
 */
export function readItemsWithCoreCollections(
  collection: string,
  query: Query<any, any>,
): RestCommand<any, any> {
  switch (collection) {
    case "directus_users":
      return readUsers(query);

    case "directus_roles":
      return readRoles(query);

    case "directus_files":
      return readFiles(query);

    default:
      if (collection.startsWith("directus_"))
        throw new Error(
          `missed handler for fetch system collection items '${collection}'. Please, define handler for this collection.`,
        );

      return readItems(collection, query);
  }
}

<template>
  <span
    class="v-icon"
    :class="[{ 'has-click': !disabled && clickable, left, right }]"
    :role="clickable ? 'button' : undefined"
    :tabindex="clickable ? 0 : undefined"
    @click="emitClick"
  >
    <component :is="customIconName" v-if="customIconName" />
    <SocialIcon v-else-if="socialIconName" :name="socialIconName" />
    <i v-else :class="{ filled }" :data-icon="name"></i>
  </span>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { IconName } from "@fortawesome/fontawesome-svg-core";
  import camelCase from "lodash/camelCase";
  import upperFirst from "lodash/upperFirst";
  import { socialIcons } from "../lib";
  import { components } from "./custom-icons/custom-icons";
  import SocialIcon from "./social-icon.vue";

  interface Props {
    /** Which type of icon to display */
    name: string | null;
    /** Removes the outline style if enabled */
    filled?: boolean;
    /** Makes the icon very small */
    sup?: boolean;
    /** Displays the icon more to the left */
    left?: boolean;
    /** Displays the icon more to the right */
    right?: boolean;
    /** Disables the icon */
    disabled?: boolean;
    /** If it should render inside a button */
    clickable?: boolean;
    /** What color for the icon to use */
    color?: string;
    /** Makes the icon smaller */
    xSmall?: boolean;
    /** Makes the icon small */
    small?: boolean;
    /** Makes the icon large */
    large?: boolean;
    /**	Makes the icon larger */
    xLarge?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    filled: false,
    sup: false,
    left: false,
    right: false,
    disabled: false,
    clickable: false,
    color: undefined,
  });

  const emit = defineEmits(["click"]);

  const customIconName = computed(() => {
    const name = `CustomIcon${upperFirst(
      camelCase(props.name?.replace(/_/g, "-") || ""),
    )}`;
    if (name in components) return components[name];
    return null;
  });

  const socialIconName = computed<IconName | null>(() => {
    if (socialIcons.includes(props.name || ""))
      return (props.name?.replace(/_/g, "-") || "") as IconName;
    return null;
  });

  function emitClick(event: MouseEvent) {
    if (props.disabled) return;
    emit("click", event);
  }
</script>

import { Logger } from "pino";
import { getToken as getFirebaseMessagingToken } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { IReactionsController } from "entities/system-reactions";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { AppEventBus, AppEvents } from "~/shared/lib/app-event-bus";
import { logger } from "~/service/logger/logger";
import { iocContainer } from "~/inversify.config";
import { tokenConfigCompiled } from "../api";
import { useFirebaseStore } from "../model";

export const getMessagingToken = async (
  serviceWorkerRegistration: ServiceWorkerRegistration,
): Promise<string | undefined> => {
  try {
    const firebaseStore = useFirebaseStore();
    const firebaseApp = firebaseStore.currentFirebaseApp;
    const logger = iocContainer.get<Logger>(INJECT_SYMBOLS.Logger);

    if (!firebaseApp) {
      return undefined;
    }

    const messaging = getMessaging(firebaseApp);

    const token = await getFirebaseMessagingToken(messaging, {
      ...tokenConfigCompiled,
      serviceWorkerRegistration,
    });

    if (!token) {
      logger.error(
        `[get-messaging-token]: Recieved Firebase Messaging Push Token is undefined.`,
      );
      return undefined;
    }

    const eventBus = iocContainer.get<AppEventBus>(INJECT_SYMBOLS.AppEventBus);

    const messagingTokenController = iocContainer.get<IReactionsController>(
      INJECT_SYMBOLS.MessagingTokenController,
    );

    messagingTokenController.registerEventBusListeners();

    logger.info(
      { token },
      `[get-messaging-token]: Recieved Firebase Messaging Push Token.`,
    );

    eventBus.dispatch({
      event: AppEvents.PUSH_TOKEN_RECIEVED,
      payload: {
        token: token,
      },
    });

    return token;
  } catch (err) {
    logger().error({ err }, `[get-messaging-token]: Can't get firebase push token.`);
    return undefined;
  }
};

import intersection from "lodash/intersection";
import cloneDeep from "lodash/cloneDeep";
import mergeWith from "lodash/mergeWith";
import set from "lodash/set";
import get from "lodash/get";
import isNil from "lodash/isNil";
import {
  FieldFilter,
  QueryFilter,
  QueryFilterOperatorsEnum,
} from "~/api/data-queries/types";
import { AppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import { IField, IFieldMeta } from "~/entities/field";
import { ISystemReactionActionContext } from "./interfaces";

/**
 *
 */
export function setContextFieldFilter(
  options: {
    fieldName: string;
    filterPath: string;
    filterRule: QueryFilterOperatorsEnum;
    filterValue: any;
  },
  context: ISystemReactionActionContext,
): void {
  const { fieldName, filterPath, filterRule, filterValue } = options;

  const field = findContextField({ fieldName }, context);
  if (!field) return;

  const oldFilter: FieldFilter<unknown> = cloneDeep(field.meta?.options?.filter || {});
  const newFilter = Object.assign({}, oldFilter);

  set(newFilter, filterPath, { [filterRule]: filterValue });
  field.meta.options.filter = newFilter;

  context.utils.Logger.debug(
    {
      ...options,
      oldFilter,
      newFilter,
    },
    `[system-reactions]: field filter property has changed`,
  );
}

/**
 *
 */
export function findContextItemPropertyData(
  options: {
    fieldPath: string;
  },
  context: ISystemReactionActionContext,
): any {
  if (!context.data) {
    context.utils.Logger.error(
      {
        fieldPath: options.fieldPath,
      },
      `[system-reactions]: context data is nill`,
    );

    return undefined;
  }
  return context.data.item.getDataProperty(options.fieldPath);
}

/**
 *
 */
export function findEventPayloadItemPropertyData(
  options: {
    fieldPath: string;
  },
  event: AppEvent<AppEvents.ITEM_DATA_CHANGED>,
): any | undefined {
  return get(event.payload.data, options.fieldPath);
}

/**
 *
 */
export function findContextField(
  options: {
    fieldName: string;
  },
  context: ISystemReactionActionContext,
): IField | undefined {
  if (!context.data) {
    context.utils.Logger.error(
      {
        fieldName: options.fieldName,
      },
      `[system-reactions]: context data is nill`,
    );

    return undefined;
  }
  return context.data.fields.find((field) => field.name === options.fieldName);
}

/**
 *
 */
export function changeContextFieldMetaProperty(
  options: {
    fieldName: string;
    metaKey: keyof IFieldMeta;
    value: IFieldMeta[keyof IFieldMeta];
  },
  context: ISystemReactionActionContext,
): void {
  const { fieldName, metaKey, value } = options;

  const field = findContextField({ fieldName }, context);

  if (!field) {
    return undefined;
  }

  const oldValue = field.meta[metaKey];
  field.meta[metaKey] = value;

  context.utils.Logger.debug(
    {
      fieldName,
      metaKey,
      oldValue,
      newValue: value,
    },
    `[system-reactions]: field meta property has changed`,
  );
}

export const hideContextFields = (
  fieldList: string[],
  context: ISystemReactionActionContext,
) => {
  fieldList.forEach((fieldName) => {
    changeContextFieldMetaProperty(
      {
        fieldName: fieldName,
        metaKey: "isHidden",
        value: true,
      },
      context,
    );
  });
  return;
};

/**
 *
 * @todo Нестабильная функция! Требуется доработка групп правил
 *  и массива правил
 */
export function setContextFieldValidationRule(
  fieldName: string,
  rule: QueryFilter<any>,
  context: ISystemReactionActionContext,
) {
  if (!context.data) {
    context.utils.Logger.error(
      {
        fieldName: fieldName,
      },
      `[system-reactions]: context data is nill`,
    );

    return undefined;
  }

  const field: IField | undefined = context.data.fields.find(
    (field) => field.name === fieldName,
  );

  if (!field) {
    return undefined;
  }

  const existingRules: QueryFilter<any>[] | undefined = cloneDeep(
    field.meta.validation?.rules?._and,
  );

  if (isNil(existingRules)) {
    field.meta.validation.rules = {
      _and: [rule],
    };
    return;
  }

  const getFieldRuleKey = (item: QueryFilter<any>) => {
    return Object.keys(item[fieldName])[0];
  };
  const getFieldRuleValue = (item: QueryFilter<any>) => {
    return Object.values(item[fieldName])[0];
  };

  if (
    !!existingRules.find(
      (item: QueryFilter<any>) => getFieldRuleKey(item) === getFieldRuleKey(rule),
    )
  ) {
    const changedRuleIndex = field.meta.validation?.rules?._and.findIndex(
      (item: QueryFilter<any>) => getFieldRuleKey(item) === getFieldRuleKey(rule),
    );

    field.meta.validation.rules._and[changedRuleIndex as number][fieldName][
      getFieldRuleKey(rule)
    ] = getFieldRuleValue(rule);

    return;
  } else {
    field.meta.validation?.rules?._and.push(rule);
    return;
  }
}

export function setContextItemPropertyDataToEmpty(
  options: { fieldPath: string },
  context: ISystemReactionActionContext,
): void {
  if (!context.data) {
    context.utils.Logger.error(
      {
        fieldPath: options.fieldPath,
      },
      `[system-reactions]: context data is nill`,
    );

    return undefined;
  }

  const fieldData = context.data.item.getDataProperty(options.fieldPath);
  if (!fieldData) return;

  const oldData =
    Array.isArray(fieldData) || typeof fieldData === "object"
      ? cloneDeep(fieldData)
      : fieldData;

  const newData = null;

  context.data.item.setDataProperty(options.fieldPath, newData);

  context.utils.Logger.debug(
    {
      fieldPath: options.fieldPath,
      oldData,
      newData,
    },
    `[system-reactions] field data has set to empty`,
  );
}

/**
 *
 */
export function addFieldFilterOptions(
  options: {
    fieldName: string;
    filter: FieldFilter<unknown>;
  },
  context: ISystemReactionActionContext,
) {
  if (!context.data) {
    context.utils.Logger.error(
      {
        fieldName: options.fieldName,
      },
      `[system-reactions]: context data is nill`,
    );

    return undefined;
  }
  const targetField = context.data.fields.find(
    (field) => field.name === options.fieldName,
  );
  if (!targetField) return;

  const oldFilter: FieldFilter<unknown> = cloneDeep(
    targetField.meta?.options?.filter || {},
  );
  const newFilter = mergeWith(oldFilter, options.filter, customizer);

  targetField.meta.options.filter = newFilter;

  context.utils.Logger.debug(
    {
      fieldName: options.fieldName,
      oldFilter,
      newFilter,
    },
    `[system-reactions]: field filter data has changed`,
  );

  /**
   *
   */
  function customizer(targetValue: any, sourceValue: any): any {
    if (typeof targetValue === "object") {
      const systemFilters = Object.keys(QueryFilterOperatorsEnum);
      const isTargetFilter: boolean = !!intersection(
        Object.keys(targetValue),
        systemFilters,
      ).length;
      const isSourceFilter: boolean = !!intersection(
        Object.keys(sourceValue),
        systemFilters,
      ).length;

      if (isTargetFilter && isSourceFilter) return sourceValue;
    }

    return targetValue;
  }
}

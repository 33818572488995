import { defineStore } from "pinia";
import { IField } from "~/entities/field";
import { IItem, ItemAnyData } from "~/entities/item";
import type { Item as DirectusItem, Field as DirectusField } from "@directus/types";

export const TRANSFORMER_STORE_ID = "transformersStore";

export interface DatastudioGatewayItemTransformer {
  to: (item: IItem) => DirectusItem;
  from: (item: DirectusItem) => IItem;
}

export interface DatastudioGatewayItemFieldTransformer {
  to: (item: IItem, field: IField) => DirectusItem;
  from: (data: any, field: DirectusField) => ItemAnyData;
}

export interface State {
  itemFieldInterfaces: { [key: string]: DatastudioGatewayItemFieldTransformer };
  defaultItemFieldTransformer: DatastudioGatewayItemFieldTransformer;
}

export const defineItemFieldTransformer = (
  transformer: DatastudioGatewayItemFieldTransformer,
): DatastudioGatewayItemFieldTransformer => {
  return transformer;
};

export const useTransformersDatastudioStore = defineStore(TRANSFORMER_STORE_ID, {
  state(): State {
    return {
      itemFieldInterfaces: {},

      defaultItemFieldTransformer: defineItemFieldTransformer({
        to: (item: IItem, field: IField): DirectusItem => {
          return {
            [field.name]: item.getDataProperty(field.name),
          };
        },
        from: (data: any): ItemAnyData => {
          return data;
        },
      }),
    };
  },

  getters: {
    getItemFieldTransformer:
      (state) =>
      (fieldInterface: string): DatastudioGatewayItemFieldTransformer | undefined => {
        if (fieldInterface in state.itemFieldInterfaces) {
          return state.itemFieldInterfaces[fieldInterface];
        }

        return state.defaultItemFieldTransformer;
      },
  },

  actions: {
    registerForFieldInterface(
      fieldInterface: string,
      transformer: DatastudioGatewayItemFieldTransformer,
    ): boolean {
      if (fieldInterface in this.itemFieldInterfaces) {
        return false;
      }

      this.itemFieldInterfaces[fieldInterface] = transformer;
      return true;
    },
  },
});

import { computed, ref } from "vue";
import { useCollecitonsStore } from "~/stores/collections";
import CollectionInterface from "../entities/CollectionInterface";

export type CollectionDataQueryVariables = {
  collectionName: string;
  currentRoleId: string;
};

export const useLazyCollectionData = () => {
  const collectionsStore = useCollecitonsStore();

  const collection = ref<CollectionInterface | null>(null);

  const isLoading = ref<boolean>(false);

  const load = async (collectionName: string) => {
    isLoading.value = true;
    collection.value = collectionsStore.getCollection(collectionName) || null;
    isLoading.value = false;
  };

  return {
    collection,
    load,
    isLoading: computed(() => isLoading.value),
  };
};

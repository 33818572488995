import "reflect-metadata";
import { defineStore } from "pinia";
import { cloneDeep } from "lodash";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { MenuDto } from "../dto/Menu.dto";
import { MenuGatewayInterface } from "../gateway/MenuGatewayInterface";

const STORE_ID = "portalMenuStore";

export interface PortalMenuState {
  items: MenuDto[];
}

export const usePortalMenuStore = defineStore(STORE_ID, {
  state(): PortalMenuState {
    return { items: [] };
  },

  getters: {
    itemsAsFormattedTree: (state): MenuDto[] => {
      const result = cloneDeep(state.items).map((menuItem) => ({
        ...menuItem,
        childrens: [] as MenuDto[],
      }));

      for (const menuItem of result) {
        if (!menuItem.parent_uuid) continue;

        const parentItem = result.find((item) => item.uuid === menuItem.parent_uuid);
        parentItem?.childrens.push(menuItem);
      }

      return result.filter((item) => !item.parent_uuid);
    },
  },

  actions: {
    async initialize(): Promise<void> {
      const apiGateway = iocContainer.get<MenuGatewayInterface>(
        INJECT_SYMBOLS.PortalMenuGateway,
      );
      const items = await apiGateway.getAllItems();

      this.$patch({
        items,
      });
    },

    async deinitialize(): Promise<void> {
      this.$reset();
    },
  },
});

import { RoleInterface } from "./interfaces";

export class Role implements RoleInterface {
  constructor(
    private _id: string,
    private _name: string,
    private _isAdmin: boolean,
    private _hasAppAccess: boolean,
  ) {}

  public get hasAppAccess(): boolean {
    return this._hasAppAccess;
  }

  public setHasAppAccess(value: boolean): this {
    this._hasAppAccess = value;
    return this;
  }

  public get isAdmin(): boolean {
    return this._isAdmin;
  }

  public setIsAdmin(value: boolean) {
    this._isAdmin = value;
    return this;
  }

  public get name(): string {
    return this._name;
  }

  public setName(value: string): this {
    this._name = value;
    return this;
  }

  public get id(): string {
    return this._id;
  }

  public setId(value: string): this {
    this._id = value;
    return this;
  }
}

<template>
  <div v-if="!!relationInfo && fieldWithRelation">
    <Menu v-if="!!items.length">
      <Menu.Button :as="Button" class="capitalize">
        {{ $t("datatable_elements_count") }}: {{ items.length }}
      </Menu.Button>

      <Menu.Items class="max-h-48 overflow-auto">
        <Menu.Item
          v-for="item in items"
          :key="item.id"
          as="div"
          class="flex items-center justify-between mb-1 cursor-default"
          @click.stop.prevent="() => {}"
        >
          <RenderTemplate
            :collectionName="relationInfo.relatedCollection"
            :item="item"
            :fieldInfo="fieldWithRelation"
            :defaultTemplate="relatedRenderTemplate ?? ''"
            :context="props.context"
          />

          <Button
            v-if="!!relatedCollection && canNavigateToRelationalItem(relatedCollection)"
            :variant="'soft-secondary'"
            class="ml-2"
            @click="
              () =>
                onRoutingToItem(
                  relatedCollection!.id,
                  item.getDataProperty(nestedItemPrimaryFieldPath!),
                )
            "
          >
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </Button>
        </Menu.Item>
      </Menu.Items>
    </Menu>

    <DataNull v-else />
  </div>
</template>

<script setup lang="ts">
  import { useRouter } from "vue-router";
  import { computed, shallowRef, watch } from "vue";
  import isEqual from "lodash/isEqual";
  import { useRelationM2M } from "~/api/relations/composables/useRelationM2M";
  import { IField, useFieldsStore } from "~/entities/field";
  import { RenderTemplate } from "~/entities/render-template";
  import DataNull from "~/components/DataNull/data-null.vue";
  import Button from "~/shared/ui/Button";
  import Menu from "~/shared/ui/Menu";
  import { transformNestedDataToItem } from "~/api/relations/utils/transform-nested-data-to-item";
  import { canNavigateToRelationalItem } from "~/api/collections/utils/availability";
  import { getItemRoute, type IItem } from "~/entities/item";
  import { FieldDisplayProp } from "../types";
  import { getFieldsFromTemplate } from "../utils";

  const props = defineProps<FieldDisplayProp>();

  const fieldsStore = useFieldsStore();
  const router = useRouter();

  const fieldWithRelation = computed<IField | undefined>(() => {
    const templateFieldNames = getFieldsFromTemplate(`{{${props.fieldKey}}}`);
    if (!templateFieldNames.length) return undefined;

    return fieldsStore.getField(props.collectionName, templateFieldNames[0]);
  });

  const { relationInfo, relatedCollection } = useRelationM2M(
    computed(() => String(props.collectionName)),
    fieldWithRelation,
  );

  const nestedItemPrimaryFieldPath = computed<string | undefined>(() => {
    if (!relatedCollection.value || !relationInfo.value) return undefined;

    const primaryField = fieldsStore.getPrimaryField(relatedCollection.value.id)!.name;
    const junctionField = relationInfo.value.junctionField!.name;

    return `${junctionField}.${primaryField}`;
  });

  const items = shallowRef<IItem[]>([]);

  watch(
    () => props.context?.data,
    async (newData, prevData) => {
      if (!relationInfo.value) return;
      if (isEqual(newData, prevData)) return;

      const relationalData = newData?.getDataProperty(props.fieldKey);
      const parentCollectionName = relationInfo.value.junctionCollection!.id;

      items.value = relationalData.currentJunctionItemIds.map(
        (itemData: Record<string, any>) =>
          transformNestedDataToItem(parentCollectionName, itemData),
      );
    },
    {
      immediate: true,
    },
  );

  const relatedRenderTemplate = computed<string | undefined>(() => {
    // todo: взять данные из индексного файла display?
    if (!relationInfo.value || !relatedCollection.value) return undefined;

    const templatePrefixFieldName = relationInfo.value.fieldName;

    const relatedTemplate = relatedCollection.value.meta?.displayTemplate;
    if (relatedTemplate) {
      const templateParts = getFieldsFromTemplate(relatedTemplate);
      const transformedTemplate = templateParts
        .map((fieldName) => `{{${templatePrefixFieldName}.${fieldName}}}`)
        .join(" ");

      return `${transformedTemplate}`;
    }

    const relatedPKFieldName = relatedCollection.value.getPrimaryFieldInfo()!.name;
    const fallbackTemplate = `{{${templatePrefixFieldName}.${relatedPKFieldName}}}`;

    return fallbackTemplate;
  });

  const onRoutingToItem = async (collectionName: string, itemId: string | number) => {
    const routeTo = getItemRoute(collectionName, itemId);
    const routePath = router.resolve(routeTo).href;

    window.open(routePath, "_blank");
  };
</script>

<style scoped></style>

import { Permission as DirectusPermission } from "@directus/types";
import { injectable } from "inversify";
import { plainToInstance } from "class-transformer";
import { PermissionInterface } from "../entity/PermissionInterface";
import { Permission } from "../entity/Permission";
import { PermissionsMapperInterface } from "./PermissionsMapperInterface";

@injectable()
export class PermissionsDirectusMapper implements PermissionsMapperInterface {
  transformToPermission(permission: DirectusPermission): PermissionInterface {
    return plainToInstance(Permission, {
      roleId: permission.role,
      collectionName: permission.collection,
      action: permission.action,
      fields: permission.fields,
      validation: permission.validation ?? {},
      presets: permission.presets ?? {},
      isSystem: permission.system ?? false,
    });
  }
}

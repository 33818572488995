import get from "lodash/get";
import unset from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { SystemReaction, findContextField } from "~/entities/system-reactions";
import { logger } from "~/service/logger/logger";
import { AppEvent, AppEvents, AppEventsPayload } from "~/shared/lib/app-event-bus";

export default new SystemReaction({
  id: "set-address-filter-on-has-declarant-change",

  scopes: [
    AppEvents.ITEM_DATA_CHANGED,
    AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
    AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW,
  ],

  priority: 0,

  matcher(event) {
    const e = event as AppEvent<
      | AppEvents.ITEM_DATA_CHANGED
      | AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW
      | AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    >;

    if (e.payload.collectionName !== "requests") return false;

    if (e.event === AppEvents.ITEM_DATA_CHANGED) {
      return (
        "has_declarant" in
        (e.payload as AppEventsPayload[AppEvents.ITEM_DATA_CHANGED]).data
      );
    }

    if (
      event.event === AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW ||
      event.event === AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    )
      return true;

    return false;
  },

  action: async (event, context) => {
    const addressField = findContextField({ fieldName: "declarant_address" }, context);
    if (!addressField) {
      logger().error(
        `[system-reaction]: unable to perform reaction. field 'declarant_address' not found.`,
      );
      return;
    }

    const currentFilter = addressField.meta?.options?.filter;
    if (!currentFilter) return;

    const declarantFilter = get(currentFilter, "premises_users.directus_users_id");
    if (!declarantFilter) return;

    const updatedFilter = cloneDeep(currentFilter);

    // удаляем корневой фильтр для заявителей
    unset(updatedFilter, "premises_users", undefined);

    addressField.meta.options.filter = updatedFilter;
  },
});

import dayjs from "dayjs";
import Comment from "../entity/Comment";
import CommentInterface from "../entity/CommentInterface";
import { CommentUser } from "../types/entity";
import { CommentGraphqlResponse } from "../types/responses";
import { CommentDto } from "../dto/comment.dto";

const commentGraphqlResponseToCommentUser = (
  response: CommentGraphqlResponse,
): CommentUser => {
  return {
    id: response.user.id,
  };
};

export const transformCommentGraphqlResponseToEntity = (
  response: CommentGraphqlResponse,
): CommentInterface => {
  return new Comment(
    parseInt(response.id),
    response.comment,
    dayjs(response.timestamp).toDate(),
    commentGraphqlResponseToCommentUser(response),
  );
};

export const transformCommentDtoToEntity = (dto: CommentDto): CommentInterface => {
  return new Comment(dto.id, dto.comment, dto.timestamp, {
    id: dto.user,
  });
};

<template>
  <iframe
    ref="iframeRef"
    :src="iframeUrl"
    class="w-full"
    v-bind="computedAttributes"
  ></iframe>
</template>

<script setup lang="ts">
  import { computed, type IframeHTMLAttributes, ref } from "vue";
  import { useFieldsStore } from "~/entities/field";
  import { FieldDisplayProp } from "../types";
  import { useIframeFieldAppHandlers } from "./composables/use-iframe-field-app-handlers";

  const props = defineProps<FieldDisplayProp>();

  const fieldsStore = useFieldsStore();

  const iframeRef = ref<HTMLIFrameElement | null>(null);

  const field = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );

  const height = computed(() => field.value?.meta?.options?.height);
  const iframeUrl = computed(() => field.value?.meta?.options?.targetUrl);

  const computedAttributes = computed<IframeHTMLAttributes>(() => {
    return {
      height: height.value || undefined,
      frameBorder: 0,
    };
  });

  useIframeFieldAppHandlers(iframeRef, field);
</script>

<style scoped></style>

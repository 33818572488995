import { CommentUser } from "../types/entity";
import CommentInterface from "./CommentInterface";

export default class Comment implements CommentInterface {
  constructor(
    public readonly id: number,
    public readonly comment: string,
    public readonly date: Date,
    public readonly user: CommentUser,
  ) {}
}

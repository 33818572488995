import { AppEvents, AppEvent } from "~/shared/lib/app-event-bus";
import {
  ISystemReaction,
  ISystemReactionActionContext,
  ISystemReactionMatcherContext,
} from "./interfaces";

export class SystemReaction implements ISystemReaction {
  private _id: string;

  private _scopes: AppEvents[];

  private _priority: number;

  private _matcher: (event: AppEvent, context: ISystemReactionMatcherContext) => boolean;

  private _action: (
    event: AppEvent,
    context: ISystemReactionActionContext,
  ) => Promise<void>;

  constructor(data: {
    id: string;
    scopes: AppEvents[];
    priority: number;
    matcher: (event: AppEvent, context: ISystemReactionMatcherContext) => boolean;
    action: (event: AppEvent, context: ISystemReactionActionContext) => Promise<void>;
  }) {
    this._id = data.id;
    this._scopes = data.scopes;
    this._priority = data.priority;
    this._matcher = data.matcher;
    this._action = data.action;
  }

  get id(): string {
    return this._id;
  }

  get scopes(): AppEvents[] {
    return this._scopes;
  }

  get priority(): number {
    return this._priority;
  }

  matcher(event: AppEvent, context: ISystemReactionMatcherContext): boolean {
    return this._matcher(event, context);
  }

  action(event: AppEvent, context: ISystemReactionActionContext): Promise<void> {
    return this._action(event, context);
  }
}

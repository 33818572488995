import * as yup from "yup";
import { IField, createTranslatedErrors } from "../lib";

export function handleFieldValidationErrors(
  field: IField,
  error: yup.ValidationError,
): string[] {
  if (field.meta.validation!.message) {
    //to-do: реализовать обращение к translationStore и получить значение по ключу ***.validaion.message соответствующей локали
    // (либо же будет храниться только текущая локаль, определяемая на initialize())
    return [field.meta.validation!.message];
  }
  return createTranslatedErrors(error.errors);
}

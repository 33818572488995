<template>
  <div v-if="displayValue">
    <span>{{ displayValue }}</span>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from "#i18n";
  import { computed } from "vue";
  import isNil from "lodash/isNil";
  import dompurify from "dompurify";
  import { decode } from "html-entities";
  import { FieldDisplayProp } from "../types";

  const { t } = useI18n();

  const props = defineProps<FieldDisplayProp>();

  const text = computed<string | null>(() => props.item.getDataProperty(props.fieldKey));

  const displayValue = computed<string | null>(() => {
    if (isNil(text.value)) return null;

    let value = String(text.value);

    if (value.startsWith("$t:")) return t(value.slice(3));

    // Strip from html entities
    value = dompurify.sanitize(value, { ALLOWED_TAGS: [] });
    value = decode(value);

    return `${value}`;
  });
</script>

<style scoped></style>

import isPlainObject from "lodash/isPlainObject";
import { useRelationsStore } from "~/stores/relations";
import { IField, defineFieldManyData } from "~/entities/field";
import { isISOString } from "~/shared/lib/date";
import { getPresentationInterfaces } from "~/api/field-interfaces";
import type { Item as ApiItem } from "@directus/types";

export function castDirectusItemFieldDataToLocal(item: ApiItem, field: IField): any {
  const fieldName = field.name;
  if (!(fieldName in item)) return null;

  const fieldData = item[fieldName];

  const relationsStore = useRelationsStore();
  const presentationInterfaces = getPresentationInterfaces();

  if (presentationInterfaces.includes(field.meta.interface)) {
    return null;
  }

  const hasRelationMany = relationsStore.relations.find(
    (relation) =>
      relation.meta?.oneField === field.name &&
      relation.meta?.oneCollection === field.collectionName,
  );

  if (hasRelationMany) {
    return defineFieldManyData({
      currentJunctionItemIds: isPlainObject(fieldData)
        ? [fieldData]
        : (fieldData as (string | number)[]),
      newRelatedItemIds: [],
      removeJunctionItemIds: [],
      create: [],
    });
  }

  if (field.meta.interface === "datetime") {
    if (!!fieldData && isISOString(fieldData, false)) {
      const data = !fieldData.endsWith("Z") ? `${fieldData}Z` : fieldData;
      return data;
    }
  }

  return fieldData;
}

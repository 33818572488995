import { useNuxtApp } from "#app";
import { print } from "graphql";
import { injectable, inject } from "inversify";
import { GraphqlClient } from "@directus/sdk";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import CommentInterface from "../entity/CommentInterface";
import { ITEM_COMMENTS_QUERY } from "../graphql/queries";
import { transformCommentGraphqlResponseToEntity } from "../transformers/gateway";
import { CommentGraphqlResponse } from "../types/responses";
import CommentsDataGatewayInterface from "./CommentsDataGatewayInterface";

@injectable()
export default class CommentsDataGateway implements CommentsDataGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioGraphqlClient)
    private readonly _graphqlClient: GraphqlClient<any>,
  ) {}

  async getAllForItem(
    collectionName: string,
    itemId: string | number,
  ): Promise<CommentInterface[]> {
    const { $i18n } = useNuxtApp();

    try {
      const response = await this._graphqlClient.query<{
        comments: CommentGraphqlResponse[];
      }>(print(ITEM_COMMENTS_QUERY), {
        collection: collectionName,
        itemId,
      });

      return response.comments.map(transformCommentGraphqlResponseToEntity);
    } catch (err) {
      logger().error({ err }, `unable to receive item comments`);
      toaster().error($i18n.t("error_comments_receive"));

      return [];
    }
  }
}

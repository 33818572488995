<template>
  <TransitionRoot
    as="div"
    enter="transition-all ease-linear duration-150"
    enterFrom="invisible opacity-0"
    enterTo="visible opacity-100"
    entered=""
    leave="transition-all ease-linear duration-150"
    leaveFrom="visible opacity-100"
    leaveTo="invisible opacity-0"
    :style="{
      height: '0px',
      width: '0px',
    }"
  >
    <div
      ref="dropdownRef"
      :class="[
        'absolute z-30',
        { 'left-0 bottom-[100%]': computedPlacement == 'top-start' },
        { 'left-[50%] translate-x-[-50%] bottom-[100%]': computedPlacement == 'top' },
        { 'right-0 bottom-[100%]': computedPlacement == 'top-end' },
        { 'left-[100%] translate-y-[-50%]': computedPlacement == 'right-start' },
        { 'left-[100%] top-[50%] translate-y-[-50%]': computedPlacement == 'right' },
        { 'left-[100%] bottom-0': computedPlacement == 'right-end' },
        { 'top-[100%] right-0': computedPlacement == 'bottom-end' },
        { 'top-[100%] left-[50%] translate-x-[-50%]': computedPlacement == 'bottom' },
        { 'top-[100%] left-0': computedPlacement == 'bottom-start' },
        { 'right-[100%] translate-y-[-50%]': computedPlacement == 'left-start' },
        { 'right-[100%] top-[50%] translate-y-[-50%]': computedPlacement == 'left' },
        { 'right-[100%] bottom-0': computedPlacement == 'left-end' },
      ]"
    >
      <HeadlessMenuItems as="template">
        <component :is="as" :class="computedClass" v-bind="omit(attrs, 'class')">
          <slot></slot>
        </component>
      </HeadlessMenuItems>
    </div>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import omit from "lodash/omit";
  import { twMerge } from "tailwind-merge";
  import { MenuItems as HeadlessMenuItems, TransitionRoot } from "@headlessui/vue";
  import { useAttrs, computed, ExtractPropTypes, ref } from "vue";

  interface ItemsProps
    extends /* @vue-ignore */ ExtractPropTypes<typeof HeadlessMenuItems> {
    as?: string | object;
    placement?:
      | "top-start"
      | "top"
      | "top-end"
      | "right-start"
      | "right"
      | "right-end"
      | "bottom-end"
      | "bottom"
      | "bottom-start"
      | "left-start"
      | "left"
      | "left-end";
    static: boolean;
  }

  const { as } = withDefaults(defineProps<ItemsProps>(), {
    as: "div",
    placement: "bottom-end",
    static: false,
  });

  const attrs = useAttrs();
  const computedClass = computed(() =>
    twMerge([
      "p-2 shadow-[0px_3px_10px_#00000017] bg-white border rounded-md dark:bg-darkmode-600 dark:border-transparent",
      typeof attrs.class === "string" && attrs.class,
    ]),
  );

  const dropdownRef = ref(null);

  const computedPlacement = computed<ItemsProps["placement"]>(() => {
    if (!dropdownRef.value) {
      return "bottom";
    }

    return hasEnoughBottomSpaceInContainer(dropdownRef.value) ? "bottom-end" : "top-end";
  });

  function hasEnoughBottomSpaceInContainer(dropdownEl: HTMLElement): boolean {
    const wrapperEl = dropdownEl.closest("[data-pc-section='wrapper']");
    if (!wrapperEl) return true;

    const wrapperHeight = wrapperEl.clientHeight;
    const wrapperPosition = wrapperEl.getBoundingClientRect().top;
    const dropdownHeight = dropdownEl.clientHeight;
    const dropdownPosition = dropdownEl.getBoundingClientRect().top;

    return dropdownPosition - wrapperPosition + dropdownHeight <= wrapperHeight;
  }
</script>

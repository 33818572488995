<script setup lang="ts">
  import * as lucideIcons from "lucide-vue-next";
  import { computed, FunctionalComponent } from "vue";

  const props = defineProps<{
    icon: keyof typeof lucideIcons;
    title?: string;
  }>();

  const icon = computed<FunctionalComponent<lucideIcons.SVGProps>>(
    () => lucideIcons[props.icon],
  );
</script>

<template>
  <component :is="icon" class="stroke-1.5" />
</template>

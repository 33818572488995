import get from "lodash/get";
import set from "lodash/set";
import isPlainObject from "lodash/isPlainObject";
import isNil from "lodash/isNil";
import { generatePrimaryKey, type ItemID } from "~/entities/item";
import { useFieldsStore } from "~/entities/field";
import { iocContainer } from "~/inversify.config";
import { AppEventBus, AppEvents } from "~/shared/lib/app-event-bus";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { IItem, IItemMeta } from "../interfaces";

export class Item<T extends object = Record<string, any>> implements IItem<T> {
  protected _isDirty: boolean = false;

  constructor(
    protected _collection: string,
    protected _data: T,
    protected _meta: IItemMeta = {},
    protected _isNew?: boolean,
  ) {
    if (this._isNew) {
      const fieldsStore = useFieldsStore();

      const primaryKeyField = fieldsStore.getPrimaryField(this._collection);
      if (!primaryKeyField) throw new Error("not found primary key field");

      const primaryKey = generatePrimaryKey(primaryKeyField);
      set(this._data, primaryKeyField.name, primaryKey);
    }
  }

  get id(): ItemID {
    const fieldsStore = useFieldsStore();

    const primaryKeyField = fieldsStore.getPrimaryField(this._collection);
    const primaryKey = get(this._data, primaryKeyField!.name);

    return primaryKey;
  }

  get collection(): string {
    return this._collection;
  }

  get data(): T {
    return this._data;
  }

  get meta(): IItemMeta {
    return this._meta;
  }

  get isNew(): boolean {
    return !!this._isNew;
  }

  get isDirty(): boolean {
    return this._isDirty;
  }

  setDataProperty(propertyOrPath: string, data: unknown): IItem<Record<string, any>> {
    const eventBus = iocContainer.get<AppEventBus>(INJECT_SYMBOLS.AppEventBus);

    set(this._data, propertyOrPath, data);

    eventBus.dispatch({
      event: AppEvents.ITEM_DATA_CHANGED,
      payload: {
        collectionName: this._collection,
        data: {
          // refactor: проверить корректность обработки значений с "."
          [propertyOrPath]: data,
        },
      },
    });

    return this;
  }

  getDataProperty(propertyOrPath: string): any {
    if (!propertyOrPath.includes(".")) {
      const data = get(this._data, propertyOrPath);
      return data || data === false ? data : null;
    }

    const [rootPath, ...paths] = propertyOrPath.split(".");

    let rootPosition = get(this._data, rootPath);
    rootPosition = this._resolveRelationalData(rootPosition);

    const foundData = this._getDataByPaths(rootPosition, paths);
    return foundData;
  }

  setMetaProperty(propertyOrPath: string, data: unknown): IItem<Record<string, any>> {
    if (isNil(this._meta)) {
      this._meta = {};
    }

    set(this._meta, propertyOrPath, data);
    return this;
  }

  setDirty(): IItem<Record<string, any>> {
    this._isDirty = true;
    return this;
  }

  setClean(): IItem<Record<string, any>> {
    this._isDirty = false;
    return this;
  }

  getMetaProperty(propertyOrPath: string): unknown {
    return get(this._meta || {}, propertyOrPath);
  }

  private _getDataByPaths(currentData: any, restPaths: string[]): any | undefined {
    if (!restPaths.length) return currentData;

    if (!isPlainObject(currentData) && !Array.isArray(currentData)) return undefined;
    if (Array.isArray(currentData) && !currentData.length) return undefined;

    const nextPath = restPaths[0];

    if (Array.isArray(currentData)) {
      const nextData = currentData
        .filter((data) => isPlainObject(data) && nextPath in data)
        .map((data) => this._resolveRelationalData(data[nextPath]));

      return this._getDataByPaths(nextData, restPaths.slice(1));
    }

    const nextData = this._resolveRelationalData(currentData[nextPath]);

    return this._getDataByPaths(nextData, restPaths.slice(1));
  }

  private _resolveRelationalData(nextData: any): any {
    return isPlainObject(nextData) && "currentJunctionItemIds" in nextData
      ? nextData.currentJunctionItemIds
      : nextData;
  }
}

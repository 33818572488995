import {
  setContextItemPropertyDataToEmpty,
  SystemReaction,
} from "~/entities/system-reactions";
import { AppEvent, AppEvents } from "~/shared/lib/app-event-bus";

export default new SystemReaction({
  id: "reset-address-after-declarant-change",

  priority: 1,

  scopes: [AppEvents.ITEM_DATA_CHANGED],

  matcher: (event) => {
    const e = event as AppEvent<AppEvents.ITEM_DATA_CHANGED>;
    return e.payload.collectionName === "requests" && "declarant_id" in e.payload.data;
  },

  action: async (event, context) => {
    setContextItemPropertyDataToEmpty({ fieldPath: "declarant_address" }, context);
  },
});

import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { GrafanaDomFinder } from "../../lib";
import { IFrameHandler } from "../types";
import type { Logger } from "pino";

@injectable()
export class GrafanaHidePanelTitleMenusHandler implements IFrameHandler {
  constructor(
    @inject(INJECT_SYMBOLS.GrafanaDomFinder)
    private readonly _grafanaDomFinder: GrafanaDomFinder,
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  handle(iframeElement: HTMLIFrameElement): void {
    const hideTitleMenusRules = `.react-grid-item [data-testid="header-container"] button[title="Menu"] {display: none;}`;

    const style = document.createElement("style") as HTMLStyleElement;
    style.innerText = hideTitleMenusRules;

    const stylesContainerElement =
      this._grafanaDomFinder.getStylesContainerElement(iframeElement);
    if (!stylesContainerElement) {
      this._logger.error(
        `[iframe:grafana:hide-panel-title-menus-handler]: unable to execute handler. Not found styles container element.`,
      );
      return;
    }

    iframeElement.contentWindow?.document.head.appendChild(style);

    this._logger.debug(
      `[iframe:grafana:hide-panel-title-menus-handler]: panel title menus was hidden`,
    );
  }
}

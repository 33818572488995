import { IItem } from "~/entities/item";

export type ItemsQueryResult = {
  data?: IItem[];
  meta?: ItemQueryMetadata | null;
};

export type ItemQueryMetadata = {
  totalCount: number;
  filterCount: number;
};

export type QueryOne<T = unknown> = {
  fields?: string[];
  search?: string;
  filter?: QueryFilter<T>;
};

export type QueryMany<T> = QueryOne<T> & {
  sort?: QuerySort;
  limit?: number;
  offset?: number;
  page?: number;
  meta?: keyof ItemQueryMetadata | "*";
  groupBy?: string | string[];
  alias?: Record<string, string>;
};

export enum QueryFilterOperatorsEnum {
  _eq = "_eq",
  _neq = "_neq",
  _gt = "_gt",
  _gte = "_gte",
  _lt = "_lt",
  _lte = "_lte",
  _in = "_in",
  _nin = "_nin",
  _between = "_between",
  _nbetween = "_nbetween",
  _contains = "_contains",
  _ncontains = "_ncontains",
  _starts_with = "_starts_with",
  _nstarts_with = "_nstarts_with",
  _ends_with = "_ends_with",
  _nends_with = "_nends_with",
  _empty = "_empty",
  _nempty = "_nempty",
  _nnull = "_nnull",
  _null = "_null",
  _intersects = "_intersects",
  _nintersects = "_nintersects",
  _intersects_bbox = "_intersects_bbox",
  _nintersects_bbox = "_nintersects_bbox",
  _regex = "_regex",
  _icontains = "_icontains",
}

export enum QueryFilterLogicalEnum {
  _and = "_and",
  _or = "_or",
}

export type QueryFilterOperators<T> = {
  [QueryFilterOperatorsEnum._eq]?: T;
  [QueryFilterOperatorsEnum._neq]?: T;
  [QueryFilterOperatorsEnum._gt]?: T;
  [QueryFilterOperatorsEnum._gte]?: T;
  [QueryFilterOperatorsEnum._lt]?: T;
  [QueryFilterOperatorsEnum._lte]?: T;
  [QueryFilterOperatorsEnum._in]?: T[];
  [QueryFilterOperatorsEnum._nin]?: T[];
  [QueryFilterOperatorsEnum._between]?: [T, T];
  [QueryFilterOperatorsEnum._nbetween]?: [T, T];
  [QueryFilterOperatorsEnum._contains]?: T;
  [QueryFilterOperatorsEnum._ncontains]?: T;
  [QueryFilterOperatorsEnum._starts_with]?: T;
  [QueryFilterOperatorsEnum._nstarts_with]?: T;
  [QueryFilterOperatorsEnum._ends_with]?: T;
  [QueryFilterOperatorsEnum._nends_with]?: T;
  [QueryFilterOperatorsEnum._empty]?: boolean;
  [QueryFilterOperatorsEnum._nempty]?: boolean;
  [QueryFilterOperatorsEnum._nnull]?: boolean;
  [QueryFilterOperatorsEnum._null]?: boolean;
  [QueryFilterOperatorsEnum._intersects]?: T;
  [QueryFilterOperatorsEnum._nintersects]?: T;
  [QueryFilterOperatorsEnum._intersects_bbox]?: T;
  [QueryFilterOperatorsEnum._nintersects_bbox]?: T;
  [QueryFilterOperatorsEnum._regex]?: string;
  [QueryFilterOperatorsEnum._icontains]?: string;
};

export type QueryLogicalFilterAnd<T> = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
};

export type QueryLogicalFilterOr<T> = {
  [QueryFilterLogicalEnum._or]: QueryFilter<T>[];
};

export type LogicalFilter<T> = QueryLogicalFilterAnd<T> | QueryLogicalFilterOr<T>;

export type QuerySingleItem = Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type FieldFilter<T> = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
};

export type QueryFilter<T> = LogicalFilter<T> | FieldFilter<T>;

export type QuerySort = (
  | `${Extract<keyof QuerySingleItem, string>}`
  | `-${Extract<keyof QuerySingleItem, string>}`
)[];

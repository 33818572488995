import { FieldFilter, Filter } from "@directus/types";
import { ValidationError } from "yup";
import flatten from "lodash/flatten";
import { QueryFilterLogicalEnum } from "~/api/data-queries/types";
import { CreateValidationSchemaOptions, createYupFilterSchema } from "~/entities/filter";
import { injectMagicFunctionResults } from "../lib/inject-magic-function-results";

export function validateFilter(
  data: Record<string, any>,
  filter: Filter,
  options?: CreateValidationSchemaOptions,
): ValidationError[] {
  const errors: ValidationError[] = [];

  const operator = Object.keys(filter)[0] as QueryFilterLogicalEnum;
  const subFilter = Object.values(filter)[0] as FieldFilter[];

  if (operator === QueryFilterLogicalEnum._and) {
    const subErrors: ValidationError[] = flatten<ValidationError>(
      subFilter.map((fieldFilter) => {
        return validateFilter(data, fieldFilter);
      }),
    ).filter((err?: ValidationError) => err);

    errors.push(...subErrors);
    return errors;
  }

  if (operator === QueryFilterLogicalEnum._or) {
    const subErrors: ValidationError[] = [];

    const isValid = subFilter.some((fieldFilter) => {
      const nestedErrors = validateFilter(data, fieldFilter, options);

      if (!!nestedErrors.length) {
        subErrors.push(...nestedErrors);
        return false;
      }

      return true;
    });

    if (!isValid) errors.push(...subErrors);

    return errors;
  }

  const dataWithMagicFunctions = injectMagicFunctionResults(data, filter);
  const schema = createYupFilterSchema(filter as FieldFilter, options ?? {});

  try {
    schema.validateSync(dataWithMagicFunctions);
  } catch (error) {
    if (error instanceof ValidationError) {
      errors.push(error);
    }
  }

  return errors;
}

import { toRef } from "vue";
import { useTransformersDatastudioStore } from "~/stores/transformersDatastudio";
import { IItem } from "~/entities/item";
import { IField, applyConditions, useCollectionFields } from "~/entities/field";
import { usePermissionsStore } from "~/stores/permissions";
import { PermissionAction } from "~/api/permissions/entity/PermissionInterface";
import type { Item as DirectusItem } from "@directus/types";

export const castItemToDirectusItem = (item: IItem): DirectusItem => {
  const transformersStore = useTransformersDatastudioStore();
  const permissionsStore = usePermissionsStore();

  const fieldNamesPermittedForAction = permissionsStore.getPermittedFields(
    item.collection,
    item.isNew ? PermissionAction.CREATE : PermissionAction.UPDATE,
  );

  let transformedItem: DirectusItem = {};

  // refactor: общая функциональность. Задублирована в FormItem
  // todo: вынести общую функциональность
  const { fields: formFieldsMap } = useCollectionFields(toRef(item.collection));

  // todo: задублированная логика в FormItem, ChangeUserPersonalData.
  //  Необходимо вынести как общий код
  const itemFields = Object.values(formFieldsMap.value).reduce<IField[]>(
    (result, field) => {
      const newField = applyConditions(item, field);

      result.push(newField);
      return result;
    },
    [],
  );

  const fieldsForTransform = itemFields
    .filter((field) => !field.meta.isHidden)
    .filter((field) => {
      if (fieldNamesPermittedForAction[0] === "*") {
        return true;
      }
      return fieldNamesPermittedForAction.includes(field.name);
    });

  for (const field of fieldsForTransform) {
    const fieldTransformer = transformersStore.getItemFieldTransformer(
      field.meta.interface as string,
    );
    const transformedItemField = fieldTransformer?.to(item, field);

    transformedItem = {
      ...transformedItem,
      ...transformedItemField,
    };
  }

  return transformedItem;
};

import get from "lodash/get";
import set from "lodash/set";
import { IItemMeta, IItem, Item } from "~/entities/item";
import { useRolesStore, RoleInterface } from "~/entities/role";
import { useFieldsStore } from "~/entities/field";
import { IUser, UserData, UserID } from "../lib/types";

export default class User extends Item implements IUser {
  constructor(protected _data: UserData) {
    super("directus_users", _data);
  }

  get id(): UserID {
    const fieldsStore = useFieldsStore();

    // todo: проверка на существование поля первичного ключа
    const primaryKeyField = fieldsStore.getPrimaryField(this._collection);
    const primaryKey = get(this._data, primaryKeyField!.name);

    return primaryKey;
  }

  get collection() {
    return "directus_users";
  }

  get data(): UserData {
    return this._data;
  }

  get meta(): IItemMeta {
    return null;
  }

  get role(): RoleInterface {
    const rolesStore = useRolesStore();
    return rolesStore.getFirstById(this._data.role)!;
  }

  setDataProperty(propertyOrPath: string, data: unknown): IItem<Record<string, any>> {
    set(this._data, propertyOrPath, data);
    return this;
  }

  getDataProperty(propertyOrPath: string): unknown {
    return get(this._data, propertyOrPath);
  }

  setMetaProperty(): IItem<Record<string, any>> {
    return this;
  }

  getMetaProperty(): unknown {
    return null;
  }
}

import { App } from "vue";
import sortBy from "lodash/sortBy";
import { IFieldForm } from "~/entities/field";
import { logger } from "~/service/logger/logger";

/**
 *
 * @returns
 */
export const getInternalFieldFormInterfaces = (): IFieldForm[] => {
  const fieldFormInterfaces = import.meta.glob<IFieldForm>(
    [`./*/index.ts`, `./*/*/index.ts`],
    {
      import: "default",
      eager: true,
    },
  );

  const result = sortBy(
    Object.values(fieldFormInterfaces),
    "type",
  ) as unknown as IFieldForm[];

  logger().debug(
    {
      loadedTypes: Object.values(fieldFormInterfaces).map(
        (fieldFormInterface) => fieldFormInterface.type,
      ),
    },
    `Loaded internal field form interfaces`,
  );

  return result;
};

/**
 *
 * @param interfaces
 * @param app
 */
export const registerFieldFormInterfaces = (interfaces: IFieldForm[], app: App) => {
  for (const formInterface of interfaces) {
    const componentName = !!formInterface.type
      ? `field-form-interface-${formInterface.interface}-${formInterface.type}`
      : `field-form-interface-${formInterface.interface}`;

    app.component(componentName, formInterface.component);
  }
};

/**
 *
 */
export const getPresentationInterfaces = (): string[] => {
  return ["presentation-divider"];
};

<template>
  <div class="flex flex-row justify-start gap-[4px]">
    <PChip
      v-for="(choice, idx) in displayChoices"
      :key="idx"
      :label="choice.text"
      :style="{
        color: choice.foreground,
        background: choice.background,
      }"
      class="px-2 py-1 rounded"
      unstyled
    />
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import isEmpty from "lodash/isEmpty";
  import isNil from "lodash/isNil";
  import PChip from "primevue/chip";
  import { IField, IFieldDisplayOptionsChoice, useFieldsStore } from "~/entities/field";
  import { FieldDisplayProp } from "../types";

  const props = defineProps<FieldDisplayProp>();

  const fieldsStore = useFieldsStore();

  const defaultChoiceForeground: string = "var(--gray-900)";
  const defaultChoiceBackground: string = "var(--surface-200)";

  const fieldInfo = computed<IField | undefined>(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );

  const fieldData = computed<any | any[]>(() =>
    props.item.getDataProperty(props.fieldKey),
  );

  const choices = computed<IFieldDisplayOptionsChoice[]>(
    () => fieldInfo.value?.meta.displayOptions?.choices ?? [],
  );

  const displayChoices = computed<IFieldDisplayOptionsChoice[]>(() => {
    let values: string[];

    if (isEmpty(fieldData.value) || isNil(fieldData.value)) {
      values = [];
    } else if (fieldInfo.value?.type === "string" && !Array.isArray(fieldData.value)) {
      values = [fieldData.value as string];
    } else {
      values = fieldData.value as string[];
    }

    return values.map((value) => {
      const choice = choices.value.find((choice) => choice.value === value);

      const defaultLabel: string =
        typeof value === "object" ? JSON.stringify(value) : value;

      if (choice === undefined) {
        return {
          value,
          text: defaultLabel,
          foreground: defaultChoiceForeground,
          background: defaultChoiceBackground,
        };
      }

      return {
        value,
        text: choice.text || defaultLabel,
        foreground: choice.foreground || defaultChoiceForeground,
        background: choice.background || defaultChoiceBackground,
      };
    });
  });
</script>

<style scoped></style>

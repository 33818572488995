<template>
  <input
    :type="type"
    :placeholder="getFieldPlaceholder(field)"
    :autocomplete="autocomplete"
    :value="props.item.getDataProperty($props.field.name)"
    :readonly="props.field.meta.isReadonly"
    :disabled="props.field.meta.isReadonly"
    :required="props.field.meta.isRequired"
    :class="[
      'form-control',
      {
        'border-danger': !isValid && isTouched,
      },
    ]"
    @input="onInput"
    @change="onChange"
    @blur="setTouched"
  />

  <ValidationMessage v-if="!!errors.length" :errors="errors" />
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import {
    FieldInterfaceEmitId,
    FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "~/api/field-interfaces/emits";
  import ValidationMessage from "~/shared/ui/ValidationMessage";
  import { useFieldValidation, getFieldPlaceholder } from "~/entities/field";
  import { FieldFormInterfaceProps } from "../types";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const autocomplete = computed(() => {
    return props.field.meta.options.masked ? "new-password" : "off";
  });

  const type = computed(() => {
    return props.field.meta.options.masked ? "password" : "text";
  });

  const onInput = (evt: Event) => {
    const input = evt.target as HTMLInputElement;

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: input.value,
      }),
    );
  };

  const onChange = (evt: Event) => {
    const input = evt.target as HTMLInputElement;

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: input.value,
      }),
    );
  };

  const { isValid, errors, isTouched, setTouched } = useFieldValidation(
    computed(() => props.item),
    computed(() => props.field),
  );
</script>

<style scoped></style>

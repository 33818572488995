import { useNuxtApp } from "#app";
import { ref } from "vue";
import { Query } from "@directus/sdk";
import { toaster } from "~/service/toaster";
import { IItem, IItemsGateway, ItemID } from "~/entities/item";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

export const useLazyCollectionItem = () => {
  const itemsGateway = iocContainer.get<IItemsGateway>(INJECT_SYMBOLS.IItemsGateway);

  const { $i18n } = useNuxtApp();

  const collectionItem = ref<IItem | null>(null);
  const isLoading = ref<boolean>(false);

  const load = async (
    collectionName: string,
    itemId: ItemID,
    query: Query<any, any> = {},
  ): Promise<void> => {
    try {
      isLoading.value = true;

      collectionItem.value = await itemsGateway.getOneById(collectionName, itemId, query);
    } catch (err) {
      toaster().error($i18n.t("server_error_fetch_items"));
    } finally {
      isLoading.value = false;
    }
  };

  return {
    collectionItem,
    load,
    isLoading,
  };
};
